/**
 * Generates a reCAPTCHA v3 token for user verification
 *
 * @async
 * @param {string} action - The reCAPTCHA action identifier (e.g., 'login', 'submit', 'verify')
 * @returns {Promise<string>} A Promise that resolves with the reCAPTCHA verification token
 */

import { REACT_APP_RECAPTCHA_SITE_KEY } from '../constants';

export default async function getRecaptchaToken(action) {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(REACT_APP_RECAPTCHA_SITE_KEY, {
          action,
        })
        .then(resolve)
        .catch(reject);
    });
  });
}
