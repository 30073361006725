import axios from 'axios';

import {
  PROMO_CODE_ERROR,
  PROMO_CODE_LOADING,
  PROMO_CODE_SUCCESS,
  FETCH_PRICE,
  PAYMENTHISTORY,
  FETCH_USER,
} from './types';

export const checkPromoCode = (promoCode) => async (dispatch) => {
  dispatch({
    type: PROMO_CODE_LOADING,
  });

  const res = await axios.get(`/api/check_promocode/${promoCode}`);

  // if (!res.data.error) {
  //   window.sessionStorage.setItem(res.data.promoType, res.data.id);
  // }
  // if something in the back failed
  if (!res.data) {
    dispatch({
      type: PROMO_CODE_ERROR,
      errorType: 'failure_to_fetch',
      errorMessage: 'Could not apply promo code',
    });
  }

  if (res.data.payAsYouGo) {
    dispatch({
      type: PROMO_CODE_SUCCESS,
      promoType: res.data.promoType,
      key: res.data.id,
    });
  }
  if (res.data.otherPlans) {
    dispatch({
      type: PROMO_CODE_SUCCESS,
      promoType: res.data.promoType,
      key: res.data.id,
      newPrice: res.data.otherTypesPrice,
      promoCodeAmount: res.data.promoCodeAmount,
    });
    // change payload in front end
  }
  if (res.data.isError) {
    dispatch({
      type: PROMO_CODE_ERROR,
      errorType: res.data.errorType,
      errorMessage: res.data.errorMessage,
    });
  }
};

export const getSingleApptPrice = (promoId) => async (dispatch) => {
  // if no promocode, dummy params to be 9 random integers - throw off the hackers
  const id = promoId || Math.random().toString().slice(2, 11);
  const res = await axios.get(`/api/get_patient_single_price/${id}`);
  if (res.data) {
    dispatch({
      type: FETCH_PRICE,
      price: res.data.patientPrice,
      isPromoApplied: res.data.isPromoApplied,
      promoCodeAmount: res.data.promoCodeAmount,
      priceId: res.data.priceId,
    });
  }
};

export const getPaymentHistory =
  (page = 1, sort = 'desc', limit = 10) =>
  async (dispatch) => {
    const res = await axios.get(
      `/api/profile/patient/payment-history?page=${page}&sort=${sort}&limit=${limit}`
    );
    dispatch({ type: PAYMENTHISTORY, payload: res.data });
  };

export const buyTokens =
  (token, amount, history, description, promoId, paymentTypeId) =>
  async (dispatch) => {
    const stripeCharge = {
      token,
      amount,
      description,
      paymentTypeId,
      promoId,
    };

    const res = await axios.post('/api/stripe', stripeCharge);
    dispatch({ type: FETCH_USER, payload: res.data });
  };
