import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  IconButton,
  withStyles,
} from '@material-ui/core';
import {
  ExpandMore,
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from '@material-ui/icons';
import * as actions from '../../../actions';

const styles = (theme) => ({
  title: {
    marginBottom: '15px',
  },
  tableHeader: {
    padding: '0px 55px 0px 15px',
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  infoTag: {
    color: theme.palette.text.secondary,
  },
});

class CanceledDeliveries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedPanel: '',
      selectedDelivery: '',
      openDialog: false,
      updateValue: '',
      sortOrder: 'desc',
    };
  }

  nextPage = () => {
    const { page } = this.props.deliveries.canceled;
    const sort = this.state.sortOrder;
    this.props.getCanceledDeliveriesList(page + 1, sort);
  };

  previousPage = () => {
    const { page } = this.props.deliveries.canceled;
    const prevPage = Math.max(1, page - 1); // Ensure page is not less than 1
    const sort = this.state.sortOrder;
    this.props.getCanceledDeliveriesList(prevPage, sort);
  };

  firstPage = () => {
    const sort = this.state.sortOrder;
    this.props.getCanceledDeliveriesList(1, sort);
  };

  lastPage = () => {
    const lastPage = this.props.deliveries.canceled.totalPages || 1;
    const sort = this.state.sortOrder;
    this.props.getCanceledDeliveriesList(lastPage, sort);
  };

  setSortOrder = (order) => {
    this.setState({
      sortOrder: order,
    });
    this.props.getCanceledDeliveriesList(1, order);
  };

  calculatePagination = (page) => {
    const { deliveries } = this.props;
    const limit = deliveries?.canceled?.limit || this.perPage;
    const totalItems = deliveries?.canceled?.totalItems || 0;
    const lastPage = deliveries?.canceled?.totalPages || 1;

    return {
      startItem: ((page || 1) - 1) * limit + 1,
      endItem: Math.min(page * limit, totalItems),
      totalItems,
      lastPage,
      limit,
    };
  };

  // eslint-disable-next-line class-methods-use-this
  renderStatusChip = (status) => (
    <Chip
      label={status.name}
      style={{
        color: 'white',
        backgroundColor: status.color,
      }}
      size="small"
    />
  );

  renderAccordion() {
    const { classes, deliveries } = this.props;
    const { expandedPanel } = this.state;
    const array = deliveries.canceled.items;
    return array.map((item) => (
      <Accordion
        key={item._id}
        id={item._id}
        expanded={expandedPanel === item._id}
        onChange={(event, isExpanded) => {
          this.setState({
            expandedPanel: isExpanded ? item._id : false,
            selectedDelivery: item,
          });
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid item xs={1}>
            <Typography className={classes.accordionHeading} align="center">
              {item.number.toString().padStart(4, '0')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.accordionHeading} align="center">
              {item.customer.name.first} {item.customer.name.last}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.accordionHeading} align="center">
              {new Date(item.date).toLocaleDateString()}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.accordionHeading} align="center">
              {new Date(item.dueDate).toLocaleDateString()}
            </Typography>
          </Grid>
          <Grid item xs={2} container justify="center">
            {this.renderStatusChip(item.status)}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                color="primary"
                className={classes.infoTag}
              >
                Patient
              </Typography>
              <Typography color="primary">
                {item.customer.name.first} {item.customer.name.last}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                color="primary"
                className={classes.infoTag}
              >
                Phone Number
              </Typography>
              <Typography color="primary">{item.phoneNumber}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                color="primary"
                className={classes.infoTag}
              >
                Address
              </Typography>
              <Typography color="primary">{item.address}</Typography>
              <Typography color="primary">
                {item.city}, {item.province}
              </Typography>
              <Typography color="primary">{item.postalCode}</Typography>
            </Grid>
            {item.notes && (
              <Grid item xs={4}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.infoTag}
                >
                  Delivery Notes
                </Typography>
                <Typography color="primary" style={{ whiteSpace: 'pre-line' }}>
                  {item.notes}
                </Typography>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    ));
  }

  handleDialogClose() {
    this.setState({ openDialog: false });
  }

  async handleSaveStatus() {
    const { selectedDelivery, updateValue } = this.state;
    const result = await axios.post('/api/pharmacy/delivery-status-update', {
      delivery: selectedDelivery._id,
      status: updateValue,
    });
    if (result.data) {
      this.setState({ updateValue: '', expandedPanel: '' });
      this.props.getCanceledDeliveriesList();
      this.handleDialogClose();
    }
  }

  render() {
    const { classes, deliveries } = this.props;
    const { page } = this.props.deliveries.canceled || {};
    const { startItem, endItem, totalItems, lastPage } =
      this.calculatePagination(page);

    return (
      <Grid container>
        <Grid item xs={12} className={classes.title}>
          <Typography variant="h5" color="primary">
            Canceled Prescriptions
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} style={{ height: '100%' }}>
          <Grid container className={classes.tableHeader}>
            <Grid item xs={1}>
              <Typography variant="subtitle2" align="center">
                No.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" align="center">
                Customer
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" align="center">
                Date
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" align="center">
                Due
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2" align="center">
                Status
              </Typography>
            </Grid>
          </Grid>
          {deliveries.canceled && deliveries.canceled.totalItems !== 0 ? (
            <Grid item style={{ height: '40vh', overflow: 'auto' }}>
              {this.renderAccordion()}
            </Grid>
          ) : (
            <Grid
              container
              style={{ height: '40vh' }}
              alignItems="center"
              justify="center"
            >
              <Typography
                variant="h6"
                align="center"
                style={{ color: 'lightgray' }}
              >
                No deliveries.
              </Typography>
            </Grid>
          )}
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={6} sm={4}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel htmlFor={'order'}>Order by</InputLabel>
                <Select
                  id="order"
                  input={<OutlinedInput label="Order by" />}
                  InputProps={{ classes: { input: classes.resize } }}
                  value={this.state.sortOrder}
                  onChange={(event) => {
                    this.setSortOrder(event.target.value);
                  }}
                >
                  {[
                    { label: 'Latest', value: 'desc' },
                    { label: 'Oldest', value: 'asc' },
                  ].map((order) => (
                    <MenuItem value={order.value} key={order.label}>
                      {order.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              style={{
                textAlign: 'center',
              }}
            >
              <Typography variant="caption" className={classes.center}>
                {`${startItem} - ${endItem} of ${totalItems}`}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={1}>
              <IconButton onClick={this.firstPage} disabled={page === 1}>
                <FirstPage />
              </IconButton>
            </Grid>
            <Grid item xs={3} sm={1}>
              <IconButton onClick={this.previousPage} disabled={page === 1}>
                <KeyboardArrowLeft />
              </IconButton>
            </Grid>

            <Grid item xs={3} sm={1}>
              <IconButton onClick={this.nextPage} disabled={page === lastPage}>
                <KeyboardArrowRight />
              </IconButton>
            </Grid>
            <Grid item xs={3} sm={1}>
              <IconButton onClick={this.lastPage} disabled={page === lastPage}>
                <LastPage />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    deliveries: state.deliveriesList,
  };
}

CanceledDeliveries.propTypes = {
  classes: PropTypes.object.isRequired,
  deliveries: PropTypes.array.isRequired,
  getCanceledDeliveriesList: PropTypes.func.isRequired,
};

const ConnectedCanceledDeliveries = connect(
  mapStateToProps,
  actions
)(withRouter(CanceledDeliveries));

export default withStyles(styles)(ConnectedCanceledDeliveries);
