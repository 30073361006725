import axios from 'axios';

const torontoTimeZone = 'America/Toronto';

function convertTo24HourFormat(timeStr) {
  const [time, period] = timeStr.split(' ');
  /* eslint-disable-next-line prefer-const */
  let [hours, minutes] = time.split(':').map(Number);

  if (period === 'PM' && hours !== 12) {
    hours += 12;
  } else if (period === 'AM' && hours === 12) {
    hours = 0;
  }

  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date;
}

function getTorontoTimeOffset(userTimeZone) {
  // Get the current date-time in Toronto and in the user's timezone
  const now = new Date();
  const torontoTime = now.toLocaleString('en-US', {
    timeZone: torontoTimeZone,
  });
  const userTime = now.toLocaleString('en-US', { timeZone: userTimeZone });

  // Convert these strings back to Date objects to calculate the time difference
  const torontoDate = new Date(torontoTime);
  const userDate = new Date(userTime);

  // Calculate the difference in hours
  const timeDifference = userDate - torontoDate;
  return timeDifference;
}

function convertTorontoTimeToUserTimeZone(torontoTimeStr) {
  const date = convertTo24HourFormat(torontoTimeStr);
  // detect user's timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Get the time difference
  const offset = getTorontoTimeOffset(userTimeZone);

  // Return the time in the user's timezone
  const userDate = new Date(date.getTime() + offset);
  return userDate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
}

/**
 * Converts clinic opening and closing hours from Toronto timezone to user's local timezone
 * @param {Object} daySchedule - Object containing clinic hours
 * @param {string} daySchedule.hourOpen - Opening time in Toronto timezone
 * @param {string} daySchedule.hourClose - Closing time in Toronto timezone
 * @returns {Object} Object containing converted opening and closing times
 * @returns {string} returns.start - Converted opening time in user's timezone
 * @returns {string} returns.end - Converted closing time in user's timezone
 */
export function getConvertedWorkingHours(daySchedule) {
  const start = convertTorontoTimeToUserTimeZone(daySchedule.hourOpen);
  const end = convertTorontoTimeToUserTimeZone(daySchedule.hourClose);

  return { start, end };
}

/**
 * Checks if the clinic is currently open for business
 * @async
 * @returns {Promise<boolean>} Promise that resolves to object with info on clinic availability
 */
export const isAppOpenForBusiness = async () => {
  const schedule = await axios.get('/api/is_clinic_available');
  return schedule.data;
};
