import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CssBaseline,
  Paper,
  Grid,
  Typography,
  Hidden,
  Drawer,
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  withStyles,
} from '@material-ui/core';
import { Dashboard, LocalShipping, Settings } from '@material-ui/icons';
import DashboardSection from './DashboardSection';
import SettingsSection from './SettingsSection';
import DeliveriesSection from './DeliveriesSection';
import logo from '../../SnapMedLogo.png';
import logoRetina from '../../SnapMedHeaderRetina.png';
import * as actions from '../../../actions';

const styles = (theme) => ({
  section: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '20px',
    },
    marginLeft: '200px',
    marginRight: '20px',
  },
  welcomeCard: {
    padding: '20px',
  },
});

class PharmacyDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSection: 'Dashboard',
      firstLoginPermission: false,
      mobileOpen: false,
      interval: '',
    };
  }

  componentDidMount() {
    this.props.getActiveDeliveriesList();
    this.props.getPendingDeliveriesList();
    this.props.getCanceledDeliveriesList();
    this.props.getDeliveredDeliveriesList();
    this.props.getPharmacyPaymentList();
    this.props.getPharmacyPromoList();
    this.props.getDeliveryStatusesList();
    this.props.getPharmacyCreditCardList();
    // this.setState({
    //   interval: setInterval(() => this.props.getDeliveriesList(), 30000),
    // });
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  handleDrawerToggle() {
    this.setState((prev) => ({ mobileOpen: !prev.mobileOpen }));
  }

  sectionSelection(value) {
    this.setState({ selectedSection: value });
  }

  navList = () => (
    <List>
      <ListItem
        button
        key="Dashboard"
        disabled={!this.props.auth.active}
        selected={this.state.selectedSection === 'Dashboard'}
        onClick={() => this.sectionSelection('Dashboard')}
      >
        <ListItemIcon>
          <Dashboard color="primary" />
        </ListItemIcon>
        <ListItemText primary="DASHBOARD" />
      </ListItem>
      <ListItem
        button
        key="Deliveries"
        disabled={!this.props.auth.active}
        selected={this.state.selectedSection === 'Deliveries'}
        onClick={() => this.sectionSelection('Deliveries')}
      >
        <ListItemIcon>
          <LocalShipping color="primary" />
        </ListItemIcon>
        <ListItemText primary="DELIVERIES" />
      </ListItem>
      <ListItem
        button
        key="Settings"
        disabled={!this.props.auth.active}
        selected={this.state.selectedSection === 'Settings'}
        onClick={() => this.sectionSelection('Settings')}
      >
        <ListItemIcon>
          <Settings color="primary" />
        </ListItemIcon>
        <ListItemText primary="SETTINGS" />
      </ListItem>
    </List>
  );

  renderSections() {
    const { selectedSection } = this.state;
    switch (selectedSection) {
      case 'Deliveries':
        return <DeliveriesSection />;
      case 'Settings':
        return <SettingsSection />;
      default:
        return <DashboardSection />;
    }
  }

  renderWelcomeCard() {
    const { classes } = this.props;
    return (
      <Paper style={{ height: '80vh' }}>
        <Grid container className={classes.welcomeCard} spacing={1}>
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Typography variant="h4" color="primary">
              Welcome!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary">
              {`It looks like you haven't yet saved your establishment's working
              hours.`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="primary">
              Please click the button below and follow the instructions.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                this.setState({
                  firstLoginPermission: true,
                  selectedSection: 'Settings',
                })
              }
            >
              Edit hours
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  render() {
    const { mobileOpen, firstLoginPermission } = this.state;
    const { classes, auth } = this.props;
    return (
      <Grid container>
        <CssBaseline />
        <Hidden smDown>
          <Drawer variant="permanent" open>
            <Toolbar>
              <img
                src={logo}
                srcSet={`
                ${logoRetina} 2x,
              `}
                width="100px"
                style={{ margin: 'auto' }}
                alt="SnapMed Logo"
              />
            </Toolbar>
            <Divider />
            {this.navList()}
          </Drawer>
        </Hidden>
        <Hidden smUp implementation="css">
          <Drawer
            // container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={this.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {this.navList()}
          </Drawer>
        </Hidden>
        <Grid container className={classes.section}>
          {auth.active || firstLoginPermission
            ? this.renderSections()
            : this.renderWelcomeCard()}
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

PharmacyDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getDeliveriesList: PropTypes.func.isRequired,
  getPharmacyPaymentList: PropTypes.func.isRequired,
  getPharmacyPromoList: PropTypes.func.isRequired,
  getDeliveryStatusesList: PropTypes.func.isRequired,
  getPharmacyCreditCardList: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const ConnectedPharmacyDashboard = connect(
  mapStateToProps,
  actions
)(withRouter(PharmacyDashboard));

export default withStyles(styles)(ConnectedPharmacyDashboard);
