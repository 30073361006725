import axios from 'axios';
import {
  FETCH_USER,
  FETCH_MEDICAL_PROFILE_COMPLETION,
  FETCH_APPOINTMENT,
  APPOINTMENT,
  DOCTORJAAS,
  APPOINTMENTHISTORY,
  SET_PATIENT_ID,
  KEEP_SYMPTOMS_DATA,
  PATIENT_ROOM,
  REFRESH_SESSION,
  SELECTED_USER,
  SELECTED_USER_MEDICAL,
  FINALIZE_PRESCRIPTION,
  FINALIZE_SICK_NOTE,
  FINALIZE_SCHOOL_SICK_NOTE,
  DRUG_INTERACTION_CHECK,
  CONTROLLED_SUBSTANCE_CHECK,
  SAVED_TEXT_CHAT,
  PHOTO_ID_VALIDATION_CHECK,
  ALLERGIES_OTHER_FIELD,
  MEDICAL_CONDITIONS_OTHER_FIELD,
  MEDICAL_PROCEDURES_OTHER_FIELD,
  CURRENT_MEDICATION_OTHER_FIELD,
  SET_PAYMENT_INTENT_ID,
  CREDIT_CARD_LIST,
  ACTIVE_DELIVERIES_LIST,
  PENDING_DELIVERIES_LIST,
  CANCELED_DELIVERIES_LIST,
  DELIVERED_DELIVERIES_LIST,
  PHARMACY_PROMO_LIST,
  DELIVERY_STATUSES_LIST,
  PHARMACY_PAYMENTS,
} from './types';

import { submitWizard } from './formActions';
import {
  logout,
  login,
  fetchUser,
  fetchUsers,
  fetchData,
  fetchTimerValues,
  fetchLocationInfo,
  fetchUserPlans,
  doctorLogin,
  pharmacyLogin,
  payAsYouGoPaymentSetup,
  payAsYouGoPaymentRemoval,
  fiveCreditPackagePaymentSetup,
  fiveCreditPackagePaymentRemoval,
  resetIdleLogout,
} from './authActions';

import {
  checkPromoCode,
  getSingleApptPrice,
  getPaymentHistory,
  buyTokens,
} from './paymentActions';

export { checkPromoCode };
export { getSingleApptPrice };
export { submitWizard };
export { logout };
export { login };
export { fetchUser };
export { fetchUsers };
export { fetchData };
export { fetchTimerValues };
export { fetchLocationInfo };
export { fetchUserPlans };
export { doctorLogin };
export { pharmacyLogin };
export { getPaymentHistory };
export { buyTokens };
export { resetIdleLogout };
export { payAsYouGoPaymentSetup, payAsYouGoPaymentRemoval };
export { fiveCreditPackagePaymentSetup, fiveCreditPackagePaymentRemoval };

export const textNotification = (values) => async (dispatch) => {
  const res = await axios.post('/api/profile/patient/settings', values);
  dispatch({ type: 'textNotification', payload: res.data });
};

export const fetchNotifications = (role) => async (dispatch) => {
  const res = await axios.get(`/api/notifications/${role}`);
  dispatch({ type: 'notification_list', payload: res.data });
};

export const selectedUserChange = (values) => async (dispatch) => {
  const res = await axios.post('/api/profile/patient/select-user', {
    ...values,
    backdrop: true,
  });

  dispatch({
    type: SELECTED_USER,
    payload: {
      ...res.data,
      pharmacyFaxNumber: res.data.patient.pharmacy.faxNumber || '',
      completed: res.data.finishedProfile,
      fullName: `${res.data.name.first} ${res.data.name.last}`,
    },
  });

  if (res.data) {
    const medical = await axios.post('/api/auth/userMedicalData', values);
    dispatch({ type: SELECTED_USER_MEDICAL, payload: medical.data });
  }
};

export const submitRegistration = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/registration', values);
  if (res.data) {
    history.push(`/emailverification/${values.email}`);
    dispatch({ type: 'fetch_user', payload: null });
    return res;
  }
  history.push('/registration/new');
  dispatch({ type: 'registration_error', payload: true });
  return { error: 'Error registering ' };
};

export const resetRegistrationError = () => (dispatch) => {
  dispatch({ type: 'registration_error', payload: false });
};

export const submitProfile = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/profile/patient', values);
  if (res.data) {
    dispatch({ type: FETCH_USER, payload: res.data });
    history.push('/dashboard');
  }
};

export const submitNewFamilyMembers = (values) => async (dispatch) => {
  try {
    const res = await axios.post('/api/profile/patient/family-members', values);
    if (!res.data) {
      // this needs to be returned so that the form can enable the submit button again
      return { error: 'Error adding family member' };
    }
    dispatch({ type: FETCH_USER, payload: res.data });
    return res;
  } catch (err) {
    return { error: 'Error adding family member' };
  }
};

export const getMedicalProfileState = () => async (dispatch) => {
  const res = await axios.get('/api/profile/patient/medical/status');
  dispatch({ type: FETCH_MEDICAL_PROFILE_COMPLETION, payload: res.data });
};

export const useCreditForAppointment = (values) => async (dispatch) => {
  const res = await axios.post(
    '/api/billing/use_credit_for_appointment',
    values
  );
  if (!res.data) {
    return { error: 'Error joining appointment' };
  }
  dispatch({ type: 'FETCH_APPOINTMENT_ID', payload: res.data });
  return res;
};

export const proceedToSeeDoctor = (values, history) => async (dispatch) => {
  axios.post('/api/patient-jaas-params', { room: values }).then((response) => {
    // response returns room, sessionId and Token
    dispatch({ type: APPOINTMENT, payload: response.data });
    history.push(`/appointmentroomp/${values}`);
  });
};

export const appointmentRoomRemovePatient = (values) => async () => {
  await axios.post('/api/finish_appointment_patient', values);
};

export const appointmentRoomHelper = (values, history) => async () => {
  if (values.finalize_prescription) {
    axios.post('/api/add_prescription', values);
  }

  if (values.finalize_general_note) {
    axios.post('/api/add_general_sicknote', values);
  }

  if (values.finalize_schoolnote) {
    axios.post('/api/add_school_sicknote', values);
  }

  axios.post('/api/finish_appointment', values).then(() => {
    axios.post('/api/add_doctor_notes', values);
    history.push('/dashboard');
  });
};

export const setPatientId = (patientId) => (dispatch) => {
  dispatch({ type: SET_PATIENT_ID, payload: patientId });
};

export const setPaymentIntentId = (paymentIntentId) => (dispatch) => {
  dispatch({ type: SET_PAYMENT_INTENT_ID, payload: paymentIntentId });
};

export const takePatient = (history) => async (dispatch) => {
  const res = await axios.get('/api/request_patient');
  if (res.data) {
    dispatch({ type: FETCH_APPOINTMENT, payload: res.data.queuePick });
    dispatch({ type: DOCTORJAAS, payload: res.data.sessionAuthDoc });
    history.push(`/appointmentroomdr/${res.data.queuePick.appointment._id}`);
  } else {
    throw new Error(
      'doctor tok params route did not return token & session id'
    );
  }
};

export const refetchCallData = (role, room) => async (dispatch) => {
  let endpoint;
  let redirection;
  let callback;

  switch (role) {
    case 'patient':
      endpoint = `/api/get_current_session_patient/${room}`;
      redirection = '/dashboard';
      callback = (res) => {
        const jaasData = {
          sessionId: res.data.jaasCredentials.sessionId,
          token: res.data.jaasCredentials.token,
          room: res.data.appointment._id,
        };
        dispatch({
          type: FETCH_APPOINTMENT,
          payload: res.data,
        });
        dispatch({
          type: APPOINTMENT,
          payload: jaasData,
        });
      };
      break;
    case 'doctor':
      endpoint = '/api/get_current_session_doctor';
      redirection = '/doctors';
      callback = (res) => {
        const jaasData = {
          sessionId: res.data.jaasCredentials.sessionId,
          docToken: res.data.jaasCredentials.docToken,
        };
        dispatch({
          type: FETCH_APPOINTMENT,
          payload: res.data,
        });
        dispatch({
          type: DOCTORJAAS,
          payload: jaasData,
        });
      };
      break;
    default:
      return;
  }

  await axios
    .get(endpoint)
    .then((res) => callback(res))
    .catch(() => {
      window.location.replace(redirection);
    });
};

export const editProfile = (values) => async (dispatch) => {
  let res;
  if (values.profileForm) {
    const payload = {
      ...values.profileForm,
      id: values.id,
      backdrop: true, // enable backdrop in HTTP request
    };
    res = await axios.post('/api/profile/patient/edit', payload);
    dispatch({
      type: SELECTED_USER,
      payload: {
        ...res.data.selectedUser,
        pharmacyFaxNumber:
          res.data.selectedUser.patient.pharmacy.faxNumber || '',
        completed: res.data.selectedUser.finishedProfile,
        fullName: `${res.data.selectedUser.name.first} ${res.data.selectedUser.name.last}`,
      },
    });
  } else if (values.medicalProfile) {
    const payload = {
      ohipNumber: values.medicalProfile.ohipNumber,
      ohipExpiration: values.medicalProfile.ohipExpiration,
      patient: { ...values.medicalProfile },
      id: values.id,
      backdrop: true, // enable backdrop in HTTP request
    };
    delete payload.patient.ohipNumber;
    delete payload.patient.ohipExpiration;

    res = await axios.post('/api/profile/patient/medical', payload);
    // Refresh medical data once we are sure we saved the updated info: this avoids several extra calls to the endpoint

    if (res && res.data) {
      const response = await axios.post('/api/auth/userMedicalData', {
        id: res.data.updatedUser._id,
        backdrop: true, // enable backdrop in HTTP request
      });
      dispatch({ type: SELECTED_USER_MEDICAL, payload: response.data });
    }
  } else if (values.DrDashboard) {
    res = await axios.post('/api/profile/doctor', values.DrDashboard);
  } else if (values.patientSettings) {
    res = await axios.post('/api/profile/patient/settings', values);
  }

  if (res && res.data) {
    dispatch({ type: FETCH_USER, payload: res.data.user });
    return res;
  }
  return { error: 'Error updating profile' };
};

export const submitDoctorSignature = (values) => async (dispatch) => {
  const res = await axios.post('/api/profile/doctor/signature', values);
  dispatch({ type: 'signature', payload: { signatureConfirmed: true } });
  return res;
};

export const getAppointmentHistory =
  (id, page = 1, sort = 'desc', limit = 10) =>
  async (dispatch) => {
    let res;
    if (id) {
      res = await axios.get(
        `/api/profile/patient/appointment-history/family/${id}?page=${page}&sort=${sort}&limit=${limit}`
      );
    } else {
      res = await axios.get(
        `/api/profile/patient/appointment-history?page=${page}&sort=${sort}&limit=${limit}`
      );
    }
    dispatch({ type: APPOINTMENTHISTORY, payload: res.data });
  };

export const getDoctorAppointmentHistory =
  (page = 1, sort = 'desc', filter = {}, limit = 10) =>
  async (dispatch) => {
    const encodedFilter = encodeURIComponent(JSON.stringify(filter));
    const res = await axios.get(
      `/api/profile/doctor/appointment-history?page=${page}&sort=${sort}&filter=${encodedFilter}&limit=${limit}`
    );
    dispatch({ type: APPOINTMENTHISTORY, payload: res.data });
    return res;
  };

export const keepSymptomsData = (values) => async (dispatch) => {
  dispatch({ type: KEEP_SYMPTOMS_DATA, payload: values });
};

export const proceedToRoom = (values, history) => async (dispatch) => {
  dispatch({ type: PATIENT_ROOM, payload: values });
  history.push('/dashboard');
};

export const leaveRoom = () => async (dispatch) => {
  dispatch({ type: PATIENT_ROOM, payload: false });
  dispatch({ type: REFRESH_SESSION, payload: false });
};

export const finalizePrescriptionAction = (values) => (dispatch) => {
  dispatch({ type: FINALIZE_PRESCRIPTION, payload: values });
};

export const finalizeSickNoteAction = (values) => (dispatch) => {
  dispatch({ type: FINALIZE_SICK_NOTE, payload: values });
};

export const finalizeSchoolSickNoteAction = (values) => (dispatch) => {
  dispatch({ type: FINALIZE_SCHOOL_SICK_NOTE, payload: values });
};

export const drugInteractionCheckboxAction = (values) => (dispatch) => {
  dispatch({ type: DRUG_INTERACTION_CHECK, payload: values });
};

export const controlledSubstanceCheckboxAction = (values) => (dispatch) => {
  dispatch({ type: CONTROLLED_SUBSTANCE_CHECK, payload: values });
};

export const photoIdValidationCheckboxAction = (values) => (dispatch) => {
  dispatch({ type: PHOTO_ID_VALIDATION_CHECK, payload: values });
};

export const savedTextChatAction = (values) => (dispatch) => {
  dispatch({ type: SAVED_TEXT_CHAT, payload: values });
};

export const enqueueOhipPatient = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/billing/enqueue_ohip_patient', values);
  if (res.data) {
    dispatch({ type: 'FETCH_APPOINTMENT_ID', payload: res.data });
    history.push('/dashboard');
  }
};

export const activateAllergiesOtherField = (values, id) => (dispatch) => {
  switch (id) {
    case 'other_allergy_Other':
      dispatch({ type: ALLERGIES_OTHER_FIELD, payload: values });
      break;
    case 'current_medical_condition_Other':
      dispatch({ type: MEDICAL_CONDITIONS_OTHER_FIELD, payload: values });
      break;
    case 'medical_procedure_Other':
      dispatch({ type: MEDICAL_PROCEDURES_OTHER_FIELD, payload: values });
      break;
    case 'current_medication_Other':
      dispatch({ type: CURRENT_MEDICATION_OTHER_FIELD, payload: values });
      break;
    default:
      break;
  }
};

export const checkCreditCardList = (values) => async (dispatch) => {
  const res = await axios.post('/api/billing/get_customer_card_list', values);
  if (res.data) {
    dispatch({ type: CREDIT_CARD_LIST, payload: res.data });
  }
};

export const getPharmacyCreditCardList = (values) => async (dispatch) => {
  const res = await axios.post('/api/pharmacy/credit-card', values);
  dispatch({ type: CREDIT_CARD_LIST, payload: res.data });
};

export const getActiveDeliveriesList =
  (
    page = 1,
    sort = 'desc',
    status = 'Filled,Received,Out for delivery,Ready for pickup',
    limit = 10
  ) =>
  async (dispatch) => {
    const res = await axios.get(
      `/api/pharmacy/deliveries?page=${page}&sort=${sort}&status=${status}&limit=${limit}`
    );
    dispatch({ type: ACTIVE_DELIVERIES_LIST, payload: res.data });
  };

export const getPendingDeliveriesList =
  (page = 1, sort = 'desc', status = 'Not delivered', limit = 10) =>
  async (dispatch) => {
    const res = await axios.get(
      `/api/pharmacy/deliveries?page=${page}&sort=${sort}&status=${status}&limit=${limit}`
    );
    dispatch({ type: PENDING_DELIVERIES_LIST, payload: res.data });
  };

export const getCanceledDeliveriesList =
  (page = 1, sort = 'desc', status = 'Canceled', limit = 10) =>
  async (dispatch) => {
    const res = await axios.get(
      `/api/pharmacy/deliveries?page=${page}&sort=${sort}&status=${status}&limit=${limit}`
    );
    dispatch({ type: CANCELED_DELIVERIES_LIST, payload: res.data });
  };

export const getDeliveredDeliveriesList =
  (page = 1, sort = 'desc', status = 'Delivered', limit = 10) =>
  async (dispatch) => {
    const res = await axios.get(
      `/api/pharmacy/deliveries?page=${page}&sort=${sort}&status=${status}&limit=${limit}`
    );
    dispatch({ type: DELIVERED_DELIVERIES_LIST, payload: res.data });
  };

export const getPharmacyPromoList = () => async (dispatch) => {
  const res = await axios.get('/api/pharmacy/promotions');
  dispatch({ type: PHARMACY_PROMO_LIST, payload: res.data });
};

export const getDeliveryStatusesList = () => async (dispatch) => {
  const res = await axios.get('/api/pharmacy/delivery-statuses');
  dispatch({ type: DELIVERY_STATUSES_LIST, payload: res.data });
};

export const getPharmacyPaymentList = () => async (dispatch) => {
  const res = await axios.get('/api/pharmacy/payment-list');
  dispatch({ type: PHARMACY_PAYMENTS, payload: res.data });
};

export const updateUser = (values) => (dispatch) => {
  dispatch({
    type: FETCH_USER,
    payload: values,
  });
};
