import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Chip,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  IconButton,
  withStyles,
} from '@material-ui/core';
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from '@material-ui/icons';
import * as actions from '../../../actions';

const styles = () => ({
  container: {
    marginTop: '3%',
  },
  tableHeader: {
    marginTop: '10px',
    padding: '5px',
    borderBottom: 'solid 1px lightgray',
  },
  deliveryList: {
    height: '30vh',
    overflow: 'auto',
  },
});

class ActiveDeliveriesCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortOrder: 'desc',
    };
  }
  // eslint-disable-next-line class-methods-use-this
  renderStatusChip = (status) => (
    <Chip
      label={status.name}
      style={{
        color: 'white',
        backgroundColor: status.color,
      }}
      size="small"
    />
  );

  nextPage = () => {
    const { page } = this.props.deliveries.active;
    const sort = this.state.sortOrder;
    this.props.getActiveDeliveriesList(page + 1, sort);
  };

  previousPage = () => {
    const { page } = this.props.deliveries.active;
    const prevPage = Math.max(1, page - 1); // Ensure page is not less than 1
    const sort = this.state.sortOrder;
    this.props.getActiveDeliveriesList(prevPage, sort);
  };

  firstPage = () => {
    const sort = this.state.sortOrder;
    this.props.getActiveDeliveriesList(1, sort);
  };

  lastPage = () => {
    const lastPage = this.props.deliveries.active.totalPages || 1;
    const sort = this.state.sortOrder;
    this.props.getActiveDeliveriesList(lastPage, sort);
  };

  setSortOrder = (order) => {
    this.setState({
      sortOrder: order,
    });
    this.props.getActiveDeliveriesList(1, order);
  };

  calculatePagination = (page) => {
    const { deliveries } = this.props;
    const limit = deliveries?.active?.limit || this.perPage;
    const totalItems = deliveries?.active?.totalItems || 0;
    const lastPage = deliveries?.active?.totalPages || 1;

    return {
      startItem: ((page || 1) - 1) * limit + 1,
      endItem: Math.min(page * limit, totalItems),
      totalItems,
      lastPage,
      limit,
    };
  };

  renderDeliveries = () =>
    this.props.deliveries.active.items.map((delivery) => {
      const date = new Date(delivery.date).toLocaleDateString('en-CA');
      const dueDate = new Date(delivery.dueDate).toLocaleDateString('en-CA');

      return (
        <Grid
          container
          style={{
            padding: '5px 0px',
          }}
        >
          <Grid item xs={2}>
            <Typography align="center">
              {String(delivery.number).padStart(4, '0')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              {delivery.customer.name.first} {delivery.customer.name.last}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{date}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{dueDate}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {this.renderStatusChip(delivery.status)}
            </Typography>
          </Grid>
        </Grid>
      );
    });

  render() {
    const { classes, deliveries } = this.props;

    const { page } = this.props.deliveries.active || {};
    const { startItem, endItem, totalItems, lastPage } =
      this.calculatePagination(page);
    return (
      <Grid item xs={12}>
        <Grid container className={classes.tableHeader}>
          <Grid item xs={2}>
            <Typography variant="subtitle2" align="center">
              #
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">Customer</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">Date</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">Due</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">Status</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.deliveryList}>
          {deliveries.active && deliveries.active.totalItems !== 0 ? (
            this.renderDeliveries()
          ) : (
            <Grid
              container
              style={{ height: '100%' }}
              alignItems="center"
              justify="center"
            >
              <Typography
                variant="h6"
                align="center"
                style={{ color: 'lightgray' }}
              >
                No deliveries.
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ marginTop: '15px' }}
        >
          <Grid item xs={6} sm={4}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel htmlFor={'order'}>Order by</InputLabel>
              <Select
                id="order"
                input={<OutlinedInput label="Order by" />}
                InputProps={{ classes: { input: classes.resize } }}
                value={this.state.sortOrder}
                onChange={(event) => {
                  this.setSortOrder(event.target.value);
                }}
              >
                {[
                  { label: 'Latest', value: 'desc' },
                  { label: 'Oldest', value: 'asc' },
                ].map((order) => (
                  <MenuItem value={order.value} key={order.label}>
                    {order.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            style={{
              textAlign: 'center',
            }}
          >
            <Typography variant="caption" className={classes.center}>
              {`${startItem} - ${endItem} of ${totalItems}`}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={1}>
            <IconButton onClick={this.firstPage} disabled={page === 1}>
              <FirstPage />
            </IconButton>
          </Grid>
          <Grid item xs={3} sm={1}>
            <IconButton onClick={this.previousPage} disabled={page === 1}>
              <KeyboardArrowLeft />
            </IconButton>
          </Grid>

          <Grid item xs={3} sm={1}>
            <IconButton onClick={this.nextPage} disabled={page === lastPage}>
              <KeyboardArrowRight />
            </IconButton>
          </Grid>
          <Grid item xs={3} sm={1}>
            <IconButton onClick={this.lastPage} disabled={page === lastPage}>
              <LastPage />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    deliveries: state.deliveriesList,
  };
}

ActiveDeliveriesCard.propTypes = {
  classes: PropTypes.object.isRequired,
  deliveries: PropTypes.array.isRequired,
};

const ConnectedActiveDeliveriesCard = connect(
  mapStateToProps,
  actions
)(withRouter(ActiveDeliveriesCard));

export default withStyles(styles)(ConnectedActiveDeliveriesCard);
