import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import SymptomsFormOhip from './SymptomsFormOhip';

class SeeDoctorRootOhip extends Component {
  renderContent() {
    return (
      <SymptomsFormOhip
        patientId={this.props.appointmentParams.patientId}
        initialValues={{
          ohip_card_error: !this.props.selectedUserMedical.ohipCardImage,
          ohipNumber: this.props.selectedUserMedical.ohipNumber,
          ohipExpiration: this.props.selectedUserMedical.ohipExpiration,
        }}
      />
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    appointmentParams: state.appointmentParams,
    selectedUserMedical: state.selectedUserMedical,
    ohipAppointment: state.ohipAppointment,
  };
}
SeeDoctorRootOhip.propTypes = {
  auth: PropTypes.oneOfType([PropTypes.object]).isRequired,
  appointmentParams: PropTypes.oneOfType([PropTypes.object]).isRequired,
  selectedUserMedical: PropTypes.oneOfType([PropTypes.object]).isRequired,
  ohipAppointment: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(mapStateToProps, actions)(SeeDoctorRootOhip);
