import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  IconButton,
  withStyles,
} from '@material-ui/core';
import {
  ExpandMore,
  Update,
  Save,
  Cancel,
  CheckCircleOutline,
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from '@material-ui/icons';
import axios from 'axios';
import * as actions from '../../../actions';

const styles = (theme) => ({
  title: {
    marginBottom: '15px',
  },
  tableHeader: {
    padding: '0px 55px 0px 15px',
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  confirmButton: {
    color: 'mediumseagreen',
  },
});

class PendingDeliveries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedPanel: '',
      selectedDelivery: '',
      openDialog: false,
      statusValue: '',
      notesValue: '',
      disableSaveButton: false,
      renderWarning: false,
      sortOrder: 'desc',
    };
  }

  componentDidUpdate() {
    this.setState((prevState) => {
      if (prevState.notesValue === '') {
        return { notesValue: this.state.selectedDelivery.notes };
      }
      return null;
    });
  }

  nextPage = () => {
    const { page } = this.props.deliveries.pending;
    const sort = this.state.sortOrder;
    this.props.getPendingDeliveriesList(page + 1, sort);
  };

  previousPage = () => {
    const { page } = this.props.deliveries.pending;
    const prevPage = Math.max(1, page - 1); // Ensure page is not less than 1
    const sort = this.state.sortOrder;
    this.props.getPendingDeliveriesList(prevPage, sort);
  };

  firstPage = () => {
    const sort = this.state.sortOrder;
    this.props.getPendingDeliveriesList(1, sort);
  };

  lastPage = () => {
    const lastPage = this.props.deliveries.pending.totalPages || 1;
    const sort = this.state.sortOrder;
    this.props.getPendingDeliveriesList(lastPage, sort);
  };

  setSortOrder = (order) => {
    this.setState({
      sortOrder: order,
    });
    this.props.getPendingDeliveriesList(1, order);
  };

  calculatePagination = (page) => {
    const { deliveries } = this.props;
    const limit = deliveries?.pending?.limit || this.perPage;
    const totalItems = deliveries?.pending?.totalItems || 0;
    const lastPage = deliveries?.pending?.totalPages || 1;

    return {
      startItem: ((page || 1) - 1) * limit + 1,
      endItem: Math.min(page * limit, totalItems),
      totalItems,
      lastPage,
      limit,
    };
  };

  // eslint-disable-next-line class-methods-use-this
  renderStatusChip = (status) => (
    <Chip
      label={status.name}
      style={{
        color: 'white',
        backgroundColor: status.color,
      }}
      size="small"
    />
  );

  handleStatusChange(value) {
    this.setState({ statusValue: value });
    const selectedValue = value;
    const optionsWithSms = this.props.statuses.filter(
      (status) => status.smsMessage
    );
    const isNotSmsOption = optionsWithSms.every(
      (option) => selectedValue !== option._id
    );
    if (!isNotSmsOption) {
      this.setState({ renderWarning: true, disableSaveButton: true });
    } else {
      this.setState({ renderWarning: false, disableSaveButton: false });
    }
  }

  renderWarningMessage() {
    const { classes } = this.props;
    return (
      <Grid item xs={12} container alignItems="center">
        <Grid item xs={6}>
          <Typography variant="button" color="error">
            SMS will be sent to customer!
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.confirmButton}
            startIcon={<CheckCircleOutline />}
            size="small"
            onClick={() => this.setState({ disableSaveButton: false })}
          >
            Confirm?
          </Button>
        </Grid>
      </Grid>
    );
  }

  handleDialogClose() {
    this.setState({
      openDialog: false,
      statusValue: '',
      notesValue: '',
      renderWarning: false,
      disableSaveButton: false,
    });
  }

  async handleSaveStatus() {
    const { selectedDelivery, statusValue, notesValue } = this.state;
    const values = {
      delivery: selectedDelivery._id,
      status: statusValue,
      notes: notesValue,
      customerName: selectedDelivery.customer.name,
      customerPhoneNumber: selectedDelivery.customer.mobilePhone,
      pharmacy: selectedDelivery.pharmacy,
    };

    const result = await axios.post(
      '/api/pharmacy/delivery-status-update',
      values
    );

    if (result.data) {
      this.setState({
        statusValue: '',
        expandedPanel: '',
        renderWarning: false,
        disableSaveButton: false,
      });
      this.props.getPendingDeliveriesList();
      this.handleDialogClose();
    }
  }
  // eslint-disable-next-line class-methods-use-this
  sortStatuses(statuses) {
    const sorted = statuses.sort((a, b) => a.status - b.status);
    return sorted;
  }

  renderAccordion() {
    const { classes, deliveries } = this.props;
    const { expandedPanel } = this.state;
    const array = deliveries.pending.items;
    array.map((item) => (
      <Accordion
        key={item._id}
        id={item._id}
        expanded={expandedPanel === item._id}
        onChange={(event, isExpanded) => {
          this.setState({
            expandedPanel: isExpanded ? item._id : false,
            selectedDelivery: item,
          });
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid item xs={1}>
            <Typography className={classes.accordionHeading} align="center">
              {item.number.toString().padStart(4, '0')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.accordionHeading} align="center">
              {item.customer.name.first} {item.customer.name.last}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.accordionHeading} align="center">
              {new Date(item.date).toLocaleDateString()}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.accordionHeading} align="center">
              {new Date(item.dueDate).toLocaleDateString()}
            </Typography>
          </Grid>
          <Grid item xs={2} container justify="center">
            {this.renderStatusChip(item.status)}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                color="primary"
                className={classes.infoTag}
              >
                Patient
              </Typography>
              <Typography color="primary">
                {item.customer.name.first} {item.customer.name.last}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                color="primary"
                className={classes.infoTag}
              >
                Phone Number
              </Typography>
              <Typography color="primary">{item.phoneNumber}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                color="primary"
                className={classes.infoTag}
              >
                Address
              </Typography>
              <Typography color="primary">{item.address}</Typography>
              <Typography color="primary">
                {item.city}, {item.province}
              </Typography>
              <Typography color="primary">{item.postalCode}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                color="primary"
                className={classes.infoTag}
              >
                Delivery Notes
              </Typography>
              <Typography color="primary" style={{ whiteSpace: 'pre-line' }}>
                {item.notes}
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <Button
            startIcon={<Update />}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => this.setState({ openDialog: true })}
          >
            Update
          </Button>
        </AccordionActions>
      </Accordion>
    ));
  }

  render() {
    const { classes, statuses, deliveries } = this.props;
    const {
      openDialog,
      selectedDelivery,
      statusValue,
      notesValue,
      disableSaveButton,
      renderWarning,
    } = this.state;
    const { page } = this.props.deliveries.pending || {};
    const { startItem, endItem, totalItems, lastPage } =
      this.calculatePagination(page);

    return (
      <Grid container>
        <Grid item xs={12} className={classes.title}>
          <Typography variant="h5" color="primary">
            Pending Deliveries
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container className={classes.tableHeader}>
            <Grid item xs={1}>
              <Typography variant="subtitle2" align="center">
                No.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" align="center">
                Customer
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" align="center">
                Date
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" align="center">
                Due
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2" align="center">
                Status
              </Typography>
            </Grid>
          </Grid>
          {deliveries.pending && deliveries.pending.totalItems !== 0 ? (
            <Grid item style={{ height: '40vh', overflow: 'auto' }}>
              {this.renderAccordion()}
            </Grid>
          ) : (
            <Grid
              container
              style={{ height: '40vh' }}
              alignItems="center"
              justify="center"
            >
              <Typography
                variant="h6"
                align="center"
                style={{ color: 'lightgray' }}
              >
                No deliveries.
              </Typography>
            </Grid>
          )}
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={6} sm={4}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel htmlFor={'order'}>Order by</InputLabel>
                <Select
                  id="order"
                  input={<OutlinedInput label="Order by" />}
                  InputProps={{ classes: { input: classes.resize } }}
                  value={this.state.sortOrder}
                  onChange={(event) => {
                    this.setSortOrder(event.target.value);
                  }}
                >
                  {[
                    { label: 'Latest', value: 'desc' },
                    { label: 'Oldest', value: 'asc' },
                  ].map((order) => (
                    <MenuItem value={order.value} key={order.label}>
                      {order.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              style={{
                textAlign: 'center',
              }}
            >
              <Typography variant="caption" className={classes.center}>
                {`${startItem} - ${endItem} of ${totalItems}`}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={1}>
              <IconButton onClick={this.firstPage} disabled={page === 1}>
                <FirstPage />
              </IconButton>
            </Grid>
            <Grid item xs={3} sm={1}>
              <IconButton onClick={this.previousPage} disabled={page === 1}>
                <KeyboardArrowLeft />
              </IconButton>
            </Grid>

            <Grid item xs={3} sm={1}>
              <IconButton onClick={this.nextPage} disabled={page === lastPage}>
                <KeyboardArrowRight />
              </IconButton>
            </Grid>
            <Grid item xs={3} sm={1}>
              <IconButton onClick={this.lastPage} disabled={page === lastPage}>
                <LastPage />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          fullWidth
          open={openDialog}
          onClose={() => this.handleDialogClose()}
        >
          <DialogTitle>Update Delivery</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.infoTag}
                >
                  Prescription No.
                </Typography>
                <Typography color="primary">
                  {selectedDelivery.number}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.infoTag}
                >
                  Patient
                </Typography>
                <Typography color="primary">
                  {selectedDelivery.customer &&
                    selectedDelivery.customer.name.first}{' '}
                  {selectedDelivery.customer &&
                    selectedDelivery.customer.name.last}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.infoTag}
                >
                  Address
                </Typography>
                <Typography color="primary">
                  {selectedDelivery.address}
                </Typography>
                <Typography color="primary">
                  {selectedDelivery.city}, {selectedDelivery.province}
                </Typography>
                <Typography color="primary">
                  {selectedDelivery.postalCode}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.infoTag}
                >
                  Phone Number
                </Typography>
                <Typography color="primary">
                  {selectedDelivery.phoneNumber}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    fullWidth
                    multiline
                    variant="outlined"
                    label="Delivery Notes"
                    type="text"
                    id="deliveryNotes"
                    value={notesValue || selectedDelivery.notes}
                    onChange={(e) =>
                      this.setState({ notesValue: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  // error={touched && error}
                >
                  <InputLabel>Update Status</InputLabel>
                  <Select
                    id="pending-status-update"
                    input={<OutlinedInput label="Update Status" />}
                    value={
                      statusValue ||
                      (selectedDelivery.status && selectedDelivery.status._id)
                    }
                    onChange={(e) => this.handleStatusChange(e.target.value)}
                  >
                    {statuses.map((status) => (
                      <MenuItem value={status._id} key={status._id}>
                        {status.name}{' '}
                        {status.smsMessage && '- SMS WILL BE SENT'}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* <FormHelperText>{touched && error}</FormHelperText> */}
                </FormControl>
              </Grid>
              {renderWarning && this.renderWarningMessage()}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Cancel />}
              style={{
                color: 'crimson',
              }}
              onClick={() => this.handleDialogClose()}
            >
              Cancel
            </Button>
            <Button
              startIcon={<Save />}
              variant="contained"
              color="primary"
              size="small"
              disabled={disableSaveButton || !statusValue}
              onClick={() => this.handleSaveStatus()}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    deliveries: state.deliveriesList,
    statuses: state.deliveryStatusesList,
  };
}

PendingDeliveries.propTypes = {
  deliveries: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  getPendingDeliveriesList: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const ConnectedPendingDeliveries = connect(
  mapStateToProps,
  actions
)(withRouter(PendingDeliveries));

export default withStyles(styles)(ConnectedPendingDeliveries);
