import React, { Component } from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { passwordStrength } from 'check-password-strength';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  Button,
  TextField,
  IconButton,
  Collapse,
  withStyles,
} from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';

import PasswordStrengthBar from '../../commonform/PasswordStrengthBar';
import * as actions from '../../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  passwordField: {
    marginBottom: '16px',
  },
  passwordMessage: {
    color: 'seagreen',
  },
});

class DrPasswordEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPasswordForm: false,
      passwordMessage: '',
      strength: { id: -1 },
    };
  }

  oldPasswordField = ({
    name,
    placeholder,
    input,
    disabled,
    meta: { touched, error },
  }) => (
    <TextField
      {...input}
      size="small"
      disabled={disabled}
      autoComplete="off"
      variant="outlined"
      name={name}
      type="password"
      label={this.state.openPasswordForm ? placeholder : '••••••••'}
      error={touched && error}
      helperText={touched && error ? error : null}
    />
  );

  // eslint-disable-next-line class-methods-use-this
  newPasswordField = ({
    name,
    placeholder,
    input,
    meta: { touched, error },
  }) => (
    <TextField
      {...input}
      size="small"
      autoComplete="off"
      variant="outlined"
      name={name}
      type="password"
      label={placeholder}
      error={touched && error}
      helperText={touched && error ? error : null}
    />
  );

  async sendPassword(values) {
    const { oldPassword, password } = values;
    const username = this.props.auth.email;

    try {
      const res = await axios.post('/api/auth/update-password-doctor', {
        oldPassword,
        password,
        username,
      });

      this.setState({ passwordMessage: res.data, openPasswordForm: false });
      setTimeout(() => this.setState({ passwordMessage: '' }), 3000);
      this.props.reset();
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(
        new SubmissionError({
          oldPassword: err.response.data.error,
          _error: 'Failed',
        })
      );
    }
  }

  render() {
    const { classes, handleSubmit, error, reset } = this.props;
    const { openPasswordForm, passwordMessage } = this.state;
    return (
      <Grid item container xs={12}>
        <form
          autoComplete="off"
          id="doctor-password-form"
          onSubmit={handleSubmit((values) => this.sendPassword(values))}
        >
          <Grid container spacing={2} xs={12} className={classes.spacing}>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                Change Password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="oldPassword"
                placeholder="Current Password"
                disabled={!openPasswordForm}
                component={this.oldPasswordField}
              />
              {!openPasswordForm ? (
                <IconButton
                  size="small"
                  style={{ margin: '4px' }}
                  onClick={() => this.setState({ openPasswordForm: true })}
                >
                  <Edit />
                </IconButton>
              ) : (
                <IconButton
                  size="small"
                  style={{ margin: '4px' }}
                  onClick={() => {
                    this.setState({
                      openPasswordForm: false,
                      strength: { id: -1 },
                    });
                    reset();
                  }}
                >
                  <Close />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={12}>
              <Collapse in={openPasswordForm}>
                <Grid item xs={12} className={classes.passwordField}>
                  <Field
                    name="password"
                    placeholder="New Password"
                    component={this.newPasswordField}
                    onChange={(e, newValue) => {
                      const strength = passwordStrength(newValue);
                      this.setState({ strength });
                    }}
                  />
                  <PasswordStrengthBar
                    strength={this.state.strength}
                    style={{
                      width: '226px',
                      marginBottom: '16px',
                      marginTop: '8px',
                    }}
                  />
                </Grid>
                <Grid item xs={12} className={classes.passwordField}>
                  <Field
                    name="confirmPassword"
                    placeholder="Confirm New Password"
                    component={this.newPasswordField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="small"
                    disabled={
                      this.props.pristine ||
                      !this.props.valid ||
                      this.state.strength.id < 2
                    }
                  >
                    {error || 'Change Password'}
                  </Button>
                </Grid>
              </Collapse>
              <Typography className={classes.passwordMessage}>
                {passwordMessage}
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

function validate(values) {
  const errors = {};
  if (!values.oldPassword) {
    errors.oldPassword = 'Please insert your current password';
  }

  if (!values.password) {
    errors.password = 'Please insert a password';
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'Please insert a password';
  }

  if (
    values.password &&
    values.confirmPassword &&
    values.password !== values.confirmPassword
  ) {
    errors.confirmPassword = 'New passwords must match';
  }

  return errors;
}

DrPasswordEditForm.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
};

const ConnectedDrPasswordEditForm = connect(
  mapStateToProps,
  actions
)(withRouter(DrPasswordEditForm));

const DrPasswordEditFormRedux = reduxForm({
  validate,
  form: 'doctor-password-form',
  destroyOnUnmount: true,
})(ConnectedDrPasswordEditForm);

export default withStyles(styles)(DrPasswordEditFormRedux);
