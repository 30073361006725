/* eslint-disable default-param-last, func-names */
import {
  ACTIVE_DELIVERIES_LIST,
  PENDING_DELIVERIES_LIST,
  CANCELED_DELIVERIES_LIST,
  DELIVERED_DELIVERIES_LIST,
} from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case ACTIVE_DELIVERIES_LIST:
      return { ...state, active: action.payload };
    case PENDING_DELIVERIES_LIST:
      return { ...state, pending: action.payload };
    case CANCELED_DELIVERIES_LIST:
      return { ...state, canceled: action.payload };
    case DELIVERED_DELIVERIES_LIST:
      return { ...state, delivered: action.payload };
    default:
      return state;
  }
}
