import React, { Component, Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';
import {
  Grid,
  Button,
  CssBaseline,
  Paper,
  TextField,
  Typography,
  FormControl,
  Fade,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  withStyles,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';

import logoMain from '../../SnapMedLogoMain.png';
import logoMainRetina from '../../SnapMedLogoMainRetina.png';
import LoginService from '../../login/LoginService';
import * as actions from '../../../actions';
import PasswordField from '../../registration/RegistrationPasswordField';
import getRecaptchaToken from '../../../utils/getRecaptchaToken';
import { REACT_APP_VERSION } from '../../../constants';

const styles = (theme) => ({
  layout: {
    display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      maxWidth: 450,
      marginBottom: '65px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  passwordCard: {
    boxShadow: '0px 5px 5px -1px gray',
    backgroundColor: 'aliceblue',
    borderRadius: '4px',
  },
});

const usernameField = ({
  input,
  label,
  name,
  type,
  meta: { touched, error },
}) => (
  <TextField
    {...input}
    variant="outlined"
    size="small"
    fullWidth
    label={label}
    type={type}
    id={name}
    error={touched && error}
    helperText={touched && error}
    margin="normal"
    inputProps={{
      autoCapitalize: 'none',
    }}
    onChange={(event) => input.onChange(event.target.value.toLowerCase())}
  />
);

const passwordField = ({ input, label, name, meta: { touched, error } }) => (
  <FormControl fullWidth error={touched && error} margin="normal">
    <PasswordField
      input={input}
      name={name}
      label={label}
      touched={touched}
      error={error}
    />
  </FormControl>
);

const authPinField = ({ input, id, label, meta: { touched, error } }) => (
  <FormControl fullWidth error={touched && error}>
    <InputMask mask="999999" {...input} onChange={input.onChange} maskChar="">
      {(inputProps) => (
        <TextField
          {...inputProps}
          autoFocus
          disableUnderline
          type="text"
          label={label}
          id={id}
          variant="outlined"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    </InputMask>
  </FormControl>
);

class PharmacyLoginForm extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.loginService = new LoginService();

    this.state = {
      passwordIsMasked: true,
      verified: false,
      authPin: '',
      authError: '',
      showResetPasswordForm: false,
      email: '',
      recipient: '',
      authToken: '',
    };
  }

  async handleVerification(values) {
    const recaptchaToken = await getRecaptchaToken('pharmacy_login');
    const result = await axios.post('/api/auth/pharmacy/two-factor-auth', {
      ...values,
      recaptchaToken,
    });
    if (result.data) {
      toast.success(`Email with PIN was sent.`, {
        position: 'bottom-center',
        duration: 4000,
      });
      this.setState({
        verified: true,
        recipient: result.data.recipient,
        authToken: result.data.token,
      });
      this.props.reset();
    }
  }

  async handleLogin(values) {
    const recaptchaToken = await getRecaptchaToken('pharmacy_login');
    const newValues = {
      ...values,
      token: this.state.authToken,
      recaptchaToken,
    };
    this.props.pharmacyLogin(newValues, this.props.history);
  }

  async handleResendPin() {
    const recaptchaToken = await getRecaptchaToken('pharmacy_login');
    const res = await axios.post('/api/auth/pharmacy/resend-pin', {
      token: this.state.authToken,
      recaptchaToken,
    });

    if (res.data) {
      toast.success(`Email with PIN was resent.`, {
        position: 'bottom-center',
        duration: 4000,
      });
    }
  }

  async submitForgotEmail(email) {
    const res = await this.loginService.forgotPharmacyPassword(email);
    if (res) {
      toast(res, {
        duration: 5000,
        position: 'bottom-center',
        icon: <Info style={{ color: 'dodgerblue' }} />,
        style: { maxWidth: '500px' },
      });
    }
  }

  render() {
    const { handleSubmit, classes } = this.props;
    const { verified, authError, showResetPasswordForm } = this.state;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Fade in={true} timeout={500}>
            <Paper className={classes.paper}>
              <img
                alt="SnapMed Logo"
                src={logoMain}
                srcSet={`${logoMainRetina} 2x,`}
                style={{
                  marginBottom: '10px',
                }}
              />
              <Typography variant="h5" color="primary">
                PHARMACY
              </Typography>
              {!verified ? (
                <Fragment>
                  <form
                    autoComplete="off"
                    className={classes.form}
                    onSubmit={handleSubmit((values) =>
                      this.handleVerification(values)
                    )}
                  >
                    <Field
                      name="username"
                      label="Pharmacy Username"
                      type="email"
                      component={usernameField}
                    />
                    <Field
                      name="password"
                      label="Password"
                      type="password"
                      classes={this.props.classes}
                      component={passwordField}
                      passwordIsMasked={this.state.passwordIsMasked}
                    />
                    {authError !== '' && (
                      <Typography color="error" variant="body1">
                        {authError}
                      </Typography>
                    )}
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Sign in
                    </Button>
                  </form>

                  {/* control for show reset password form */}
                  <Button
                    size="small"
                    onClick={() => {
                      this.setState({
                        showResetPasswordForm: !showResetPasswordForm,
                      });
                    }}
                  >
                    forgot password?
                  </Button>
                </Fragment>
              ) : (
                <form
                  className={classes.form}
                  onSubmit={handleSubmit(this.handleLogin)}
                >
                  <Grid item xs={12} style={{ margin: '15px 0px' }}>
                    <Typography color="primary">
                      We have sent a 6 digit PIN to{' '}
                      <b>{this.state.recipient}</b>.
                    </Typography>
                  </Grid>
                  <Field
                    name="authPin"
                    label="Enter PIN"
                    type="text"
                    component={authPinField}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Submit
                  </Button>
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: '10px' }}
                    container
                    justify="center"
                    spacing={1}
                  >
                    <Grid item xs={12} c container justify="center">
                      <Typography color="primary">
                        Did not get the PIN?
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container justify="center">
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={() => this.handleResendPin()}
                      >
                        Resend PIN
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
              <Typography
                align="center"
                variant="caption"
                style={{ marginTop: 16, color: 'gray' }}
              >
                This site is protected by reCAPTCHA and the Google
                <a
                  style={{
                    marginLeft: 4,
                    marginRight: 4,
                    color: 'cornflowerblue',
                  }}
                  href="https://policies.google.com/privacy"
                >
                  Privacy Policy
                </a>
                and
                <a
                  style={{
                    marginLeft: 4,
                    marginRight: 4,
                    color: 'cornflowerblue',
                  }}
                  href="https://policies.google.com/terms"
                >
                  Terms of Service
                </a>
                apply.
              </Typography>
              <Typography
                align="center"
                variant="caption"
                style={{ marginTop: 16 }}
              >
                SnapMED v{REACT_APP_VERSION}
              </Typography>
            </Paper>
          </Fade>
          <Dialog
            open={showResetPasswordForm}
            onClose={() => this.setState({ showResetPasswordForm: false })}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Forgot Password</DialogTitle>
            <DialogContent>
              <Typography variant="body1" color="primary">
                Email associated to your account:
              </Typography>
              <TextField
                autoComplete="off"
                id="email"
                type="email"
                label="Email"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() =>
                  this.setState({ showResetPasswordForm: false, email: '' })
                }
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  this.submitForgotEmail(this.state.email);
                  this.setState({
                    showResetPasswordForm: false,
                    email: '',
                  });
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </main>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = {};
  if (!values.username) {
    errors.username = 'Field required';
  }
  if (!values.password) {
    errors.password = 'Field required';
  }

  return errors;
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    idleLogout: state.idleLogout,
  };
}

PharmacyLoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  pharmacyLogin: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
};

const ConnectedPharmacyLoginForm = connect(
  mapStateToProps,
  actions
)(withRouter(PharmacyLoginForm));

const PharmacyLoginFormRedux = reduxForm({
  validate,
  form: 'pharmacyLoginForm',
  destroyOnUnmount: true,
})(ConnectedPharmacyLoginForm);

export default withStyles(styles)(PharmacyLoginFormRedux);
