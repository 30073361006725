import { EventEmitter } from '../classes/event-emitter';

class AuthApi extends EventEmitter {
  #authenticated = false;
  #userType = null;

  isAuthenticated() {
    return this.#authenticated;
  }

  setAuthenticated(state, type = '') {
    if (typeof state === 'boolean') {
      this.#authenticated = state;
      this.setUserType(type);
      if (state) this.emit('authenticated');
      else this.emit('unauthenticated');
    }
  }

  setUserType(type) {
    this.#userType = type;
  }

  getUserType() {
    return this.#userType;
  }
}

export const authApi = new AuthApi();
