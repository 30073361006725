import React, { Component, Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  Button,
  Paper,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  FormControl,
  withStyles,
} from '@material-ui/core';
import { passwordStrength } from 'check-password-strength';
import logoMain from '../../SnapMedLogoMain.png';
import logoMainRetina from '../../SnapMedLogoMainRetina.png';

import PasswordStrengthBar from '../../commonform/PasswordStrengthBar';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.down(400 + theme.spacing.unit * 3 * 2)]: {
      width: 350,
      marginBottom: '65px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginBottom: '65px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    margin: '30px 0px',
  },
  fieldSpacing: {
    marginBottom: '8px',
  },
});

const passwordField = ({
  name,
  input,
  label,
  strength,
  meta: { touched, error },
}) => (
  <FormControl fullWidth error={touched && error} margin="normal">
    <TextField
      {...input}
      size="small"
      error={touched && error}
      helperText={touched && error}
      fullWidth
      id={name}
      name={name}
      type="password"
      label={label}
      variant="outlined"
      autoComplete="off"
    />
    {input.value && (
      <PasswordStrengthBar strength={strength} style={{ marginTop: '10px' }} />
    )}
  </FormControl>
);

const confirmPasswordField = ({
  name,
  input,
  label,
  meta: { touched, error },
}) => (
  <FormControl fullWidth error={touched && error} margin="normal">
    <TextField
      {...input}
      size="small"
      error={touched && error}
      helperText={touched && error}
      fullWidth
      id={name}
      name={name}
      type="password"
      label={label}
      variant="outlined"
      autoComplete="off"
    />
  </FormControl>
);

class ResetPasswordPharmacy extends Component {
  constructor() {
    super();

    this.state = {
      pwdWasUpdated: false,
      isLoading: false,
      strength: { id: -1 },
    };
  }

  updatePassword = (values) => {
    this.setState({ isLoading: true });
    axios
      .put(
        `/api/auth/update-pharmacy-password-via-email/${this.props.match.params.token}`,
        {
          password: values.password,
        }
      )
      .then((response) => {
        if (response.data) {
          this.setState({ pwdWasUpdated: true });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const { isLoading, pwdWasUpdated } = this.state;

    const { classes, handleSubmit, pristine, invalid } = this.props;

    return (
      <Fragment>
        <Grid xs={12} container>
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <img
                alt="SnapMed Logo"
                src={logoMain}
                srcSet={`${logoMainRetina} 2x,`}
                style={{ marginBottom: '15px' }}
              />
              <Typography variant="h5" color="primary">
                PHARMACY
              </Typography>
              <Grid item xs={12} style={{ margin: '10px 0px' }}>
                <Typography variant="h6" color="primary">
                  {pwdWasUpdated ? 'Password Updated' : 'Reset Your Password'}
                </Typography>
              </Grid>
              {!pwdWasUpdated && (
                <form
                  autoComplete="off"
                  className={classes.form}
                  onSubmit={handleSubmit((values) =>
                    this.updatePassword(values)
                  )}
                >
                  <Grid item xs={12} className={classes.fieldSpacing}>
                    <Field
                      label="New password"
                      name="password"
                      component={passwordField}
                      strength={this.state.strength}
                      onChange={(e, newValue) => {
                        const strength = passwordStrength(newValue);
                        this.setState({ strength });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.fieldSpacing}>
                    <Field
                      label="Confirm new password"
                      name="confirmation"
                      component={confirmPasswordField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className={classes.submit}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={pristine || invalid || isLoading}
                      fullWidth
                    >
                      Submit
                      {isLoading && (
                        <CircularProgress
                          size={15}
                          color="white"
                          style={{
                            marginLeft: 5,
                          }}
                        />
                      )}
                    </Button>
                  </Grid>
                </form>
              )}
              <Grid item xs={12}>
                <Button
                  onClick={() => this.props.history.push('/pharmacy')}
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  Go to login
                </Button>
              </Grid>
            </Paper>
          </main>
        </Grid>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = {};

  if (!values.password) {
    errors.password = 'Field required';
  }
  if (!values.confirmation) {
    errors.confirmation = 'Field required';
  }
  if (
    values.password &&
    values.confirmation &&
    values.password !== values.confirmation
  ) {
    errors.confirmation = 'Passwords must match';
  }

  const strength = passwordStrength(values.password);
  if (values.password && strength.id < 2) {
    errors.password = 'Password not strong enough';
  }

  return errors;
}

ResetPasswordPharmacy.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
};

const ResetPasswordPharmacyRedux = reduxForm({
  validate,
  form: 'resetPasswordPharmacy',
  enableReinitialize: true,
})(ResetPasswordPharmacy);

export default withStyles(styles)(ResetPasswordPharmacyRedux);
