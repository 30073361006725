import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  CssBaseline,
  Typography,
  Grid,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import * as actions from '../../actions';
import PatientVideoChat from './PatientVideoChat';
import { patientSocket } from '../../api/patient-ws';

const styles = () => ({
  root: {
    flexGrow: 1, // specifies grow factor of a flex item
    padding: 5,
  },
  submitAppointment: {
    marginTop: '1%',
    float: 'right',
  },
  submitButton: {
    margin: 'auto',
  },
  popupTitle: {
    margin: 'auto',
  },
});

class PatientAppointmentRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socket: patientSocket,
      openDialog: false,
      callStart: null,
      callDuration: '',
    };
  }

  startTimer() {
    this.setState({ callStart: new Date() });
  }

  async handleCallDisconnected() {
    this.setState({ openDialog: true });
  }

  async componentDidMount() {
    const { room } = this.props.match.params;
    if (!this.props.appointmentParams.appointmentId) {
      this.props.refetchCallData('patient', room);
    }
    this.startTimer();
  }

  async componentWillUnmount() {
    const callEnd = new Date();
    const durationInMilliseconds = callEnd - this.state.callStart;
    const durationInSeconds = Math.floor(durationInMilliseconds / 1000);
    const remainderSeconds = durationInSeconds % 60;

    // Format time as 2-digit numbers
    const min = String(Math.floor(durationInSeconds / 60)).padStart(2, '0');
    const sec = String(remainderSeconds).padStart(2, '0');

    const appointment = this.props.appointmentParams.appointmentId;
    const timer = `${min} : ${sec}`;
    await axios.post('/api/update_patient_timer', {
      appointment,
      timer,
    });
    this.props.leaveRoom();
    // this.state.socket.disconnect();
    this.setState({ socket: '' });
  }

  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  };

  handleCloseDialog() {
    this.setState({ openDialog: false });
    this.props.history.push('/');
  }

  render() {
    const { openDialog } = this.state;
    const { token, appointmentId } = this.props.appointmentParams;

    return (
      <Fragment>
        <CssBaseline />
        <Grid item xs={12}>
          {token && appointmentId && (
            <PatientVideoChat
              token={token}
              room={appointmentId}
              handleCallDisconnected={() => this.handleCallDisconnected()}
            />
          )}
        </Grid>
        <Dialog
          open={openDialog}
          onClose={() => this.handleCloseDialog()}
          fullWidth
        >
          <DialogTitle>Consultation Ended</DialogTitle>
          <DialogContent>
            <Typography paragraph>
              Your virtual medical consultation is now complete. All notes and
              prescriptions from this consult can be viewed in your appointment
              history once completed by the doctor.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handleCloseDialog()}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    appointmentParams: state.appointmentParams,
  };
}

PatientAppointmentRoom.propTypes = {
  appointmentParams: PropTypes.object.isRequired,
  refetchCallData: PropTypes.func.isRequired,
  leaveRoom: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const ConnectedPatientAppointmentRoom = connect(
  mapStateToProps,
  actions
)(PatientAppointmentRoom);

export default withStyles(styles)(ConnectedPatientAppointmentRoom);
