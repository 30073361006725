import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  Grid,
  Typography,
  Button,
  Chip,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Slide,
  Select,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  IconButton,
  Divider,
  Paper,
} from '@material-ui/core';
import {
  Add,
  LocalShipping,
  Edit,
  NotInterested,
  ArrowForwardIos,
  LocalOffer,
  Delete,
} from '@material-ui/icons';
import * as actions from '../../../actions';

import EditProfileHours from './EditProfileHours';
import PharmacyPasswordEditForm from './PharmacyPasswordEditForm';

const styles = (theme) => ({
  // layout: {
  //   overflow: 'hidden',
  //   marginLeft: theme.spacing.unit,
  //   marginRight: theme.spacing.unit,
  //   [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
  //     marginLeft: theme.spacing.unit * 6,
  //     marginRight: theme.spacing.unit * 6
  //   },
  //   maxWidth: 850
  // },
  infoBlock: {
    marginTop: '10px',
  },
  promoDescription: {
    color: theme.palette.text.secondary,
  },
  activePromotions: {
    height: '90%',
    // overflowY: 'auto',
  },
  promoList: {
    marginBottom: 16,
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    borderRadius: '4px',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  paper: {
    height: '100%',
    padding: '30px',
    overflow: 'auto',
  },
});

const PromotionField = ({ input, promoArray }) => (
  <FormControl fullWidth variant="outlined" size="small">
    <InputLabel>Add a promotion</InputLabel>
    <Select {...input} input={<OutlinedInput label="Add a promotion" />}>
      <MenuItem value="">None</MenuItem>
      {promoArray.map((promo) => (
        <MenuItem value={promo._id} key={promo._id}>
          {promo.description}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
class PharmacyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editHours: false,
      initialFormValues: {},
      promoList: [],
    };
    this.buildInitialValues = this.buildInitialValues.bind(this);
  }

  sortPromoDropdown() {
    const selectedPromoList = this.props.auth.promotions;
    const { promoList } = this.props;
    let sortedList = [];
    if (selectedPromoList.length === 0) {
      sortedList = promoList;
    } else if (selectedPromoList.length === promoList.length) {
      sortedList = [];
    } else {
      sortedList = promoList.filter((promo) => {
        if (selectedPromoList.find((selected) => selected._id === promo._id)) {
          return false;
        }
        return true;
      });
    }
    this.setState({ promoList: sortedList });
  }

  buildInitialValues(authHours) {
    const hours = authHours;
    const initialValues = {};
    Object.keys(hours).forEach((item) => {
      initialValues[`${item}Open`] = hours[item].open;
      initialValues[`${item}Close`] = hours[item].close;
      initialValues[`${item}DeliveryCutoff`] = hours[item].deliveryCutoff;
    });
    this.setState({ initialFormValues: initialValues });
  }

  handlePromotionUpdate = async (values, action) => {
    const newValues = { ...values, action };

    const response = await axios.post(
      '/api/pharmacy/edit-promotions',
      newValues
    );
    if (response.data) {
      this.props.fetchUser();
      this.sortPromoDropdown();
      this.props.reset();
    }
  };

  renderPromoDescription(id) {
    const promo = this.props.promoList.find((p) => p._id === id);
    return (
      <Grid>
        <Typography color="primary">{promo.extendedDescription}</Typography>
      </Grid>
    );
  }

  componentDidMount() {
    this.buildInitialValues(this.props.auth.hours);
    this.sortPromoDropdown();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.buildInitialValues(this.props.auth.hours);
      this.sortPromoDropdown();
    }
  }

  renderHours() {
    const authHours = this.props.auth.hours;
    const hours = Object.keys(authHours).map((item, index) => {
      let open;
      let close;
      let cutoff;
      let openMeridiem;
      let closeMeridiem;
      let cutoffMeridiem;
      let openHoursString;
      let closeHoursString;
      let cutoffString;

      if (authHours[item].open) {
        open = new Date(authHours[item].open);
        open = open.toLocaleTimeString('en-US').split(':');
        // .pop() assigns the removed element to variable 'openMeridiem' and also changes the array 'open'
        openMeridiem = open.pop();
        openMeridiem = openMeridiem.slice(2);
        openHoursString = `${open[0]}:${open[1]}${openMeridiem}`;
      } else {
        openHoursString = 'N/A';
      }

      if (authHours[item].close) {
        close = new Date(authHours[item].close);
        close = close.toLocaleTimeString('en-US').split(':');
        // .pop() assigns the removed element to variable 'closeMeridiem' and also changes the array 'close'
        closeMeridiem = close.pop();
        closeMeridiem = closeMeridiem.slice(2);
        closeHoursString = `${close[0]}:${close[1]}${closeMeridiem}`;
      } else {
        closeHoursString = 'N/A';
      }

      if (authHours[item].deliveryCutoff) {
        cutoff = new Date(authHours[item].deliveryCutoff);
        cutoff = cutoff.toLocaleTimeString('en-US').split(':');
        // .pop() assigns the removed element to variable 'cutoffMeridiem' and also changes the array 'cutoff'
        cutoffMeridiem = cutoff.pop();
        cutoffMeridiem = cutoffMeridiem.slice(2);
        cutoffString = `${cutoff[0]}:${cutoff[1]}${cutoffMeridiem}`;
      }

      return (
        <Grid container spacing={1} alignItems="center" key={index}>
          <Grid item xs={1}>
            <Typography color="primary">
              {item[0].toUpperCase() + item.substring(1, 3)}.
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="primary" variant="h6">
              {openHoursString}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography color="primary">to</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="primary" variant="h6">
              {closeHoursString}
            </Typography>
          </Grid>
          {authHours[item].deliveryCutoff ? (
            <Grid item xs={2}>
              <Chip
                icon={<LocalShipping />}
                label={`cutoff @ ${cutoffString}`}
                color="primary"
                size="small"
              />
            </Grid>
          ) : (
            <Grid item xs={2}>
              <Chip
                icon={<NotInterested />}
                label={'No Delivery'}
                size="small"
              />
            </Grid>
          )}
        </Grid>
      );
    });

    return (
      <Fragment>
        {hours}
        {this.props.auth.active ? (
          <Grid container justify="flex-end">
            <Button
              variant="outlined"
              size="small"
              color="primary"
              startIcon={<Edit />}
              onClick={() => this.setState({ editHours: true })}
            >
              Edit
            </Button>
          </Grid>
        ) : (
          <Grid container justify="flex-end">
            <Slide in={true} direction="right" timeout={1200}>
              <Grid
                item
                xs={10}
                container
                justify="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h6" color="error">
                    CLICK HERE
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: '4px' }}>
                  <ArrowForwardIos color="error" />
                </Grid>
              </Grid>
            </Slide>
            <Grid item xs={2}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<Edit />}
                onClick={() => this.setState({ editHours: true })}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }

  render() {
    const { classes, auth, handleSubmit, pristine } = this.props;
    const { editHours, initialFormValues, promoList } = this.state;

    return (
      <Grid container spacing={4}>
        <Grid item xs={12} lg={4}>
          <Paper className={classes.paper}>
            <Grid item xs={12}>
              <Typography variant="h4" color="primary">
                {auth.name}
              </Typography>
              <Typography color="primary">{auth.address.street}</Typography>
              <Typography color="primary">
                {auth.address.city}, {auth.address.province}
              </Typography>
              <Typography color="primary">{auth.address.postalCode}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.infoBlock}>
              <Typography variant="subtitle2" color="primary">
                Phone:
              </Typography>
              <Typography color="primary">{auth.phoneNumber}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.infoBlock}>
              <Typography variant="subtitle2" color="primary">
                Fax:
              </Typography>
              <Typography color="primary">{auth.faxNumber}</Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Paper className={classes.paper}>
            <Typography variant="h6" color="primary">
              Hours
            </Typography>
            {editHours ? (
              <EditProfileHours
                initialValues={initialFormValues}
                closeEdit={() => {
                  this.buildInitialValues(auth.hours);
                  this.setState({ editHours: false });
                }}
                buildInitialValues={(values) => this.buildInitialValues(values)}
              />
            ) : (
              this.renderHours()
            )}
          </Paper>
        </Grid>
        {!editHours && (
          <Fragment>
            <Grid item xs={12}>
              <Typography variant="h4" color="primary">
                Promotions
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper className={classes.paper}>
                <form
                  onSubmit={handleSubmit((values) =>
                    this.handlePromotionUpdate(values, 'add')
                  )}
                  style={{
                    width: '100%',
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={10}>
                      <Field
                        name="pharmacyPromotion"
                        promoArray={promoList}
                        component={PromotionField}
                      />
                    </Grid>
                    <Grid itemxs={2}>
                      <IconButton type="submit" disabled={pristine}>
                        <Add />
                      </IconButton>
                    </Grid>
                  </Grid>
                </form>
                <Grid item xs={12}>
                  <Typography color="primary" variant="h6">
                    Promotion Description
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {this.props.selectedPromotion ? (
                    this.renderPromoDescription(this.props.selectedPromotion)
                  ) : (
                    <Grid>
                      <Typography className={classes.promoDescription}>
                        No Promotion Selected
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper className={classes.paper}>
                <Grid item xs={12}>
                  <Typography
                    color="primary"
                    variant="h6"
                    style={{ marginLeft: '8px' }}
                  >
                    Active Promotions
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.activePromotions}>
                  <List>
                    {this.props.auth.promotions.map((promo, index) => (
                      <Fragment key={index}>
                        {index > 0 && <Divider component="li" />}
                        <ListItem xs={12}>
                          <ListItemAvatar>
                            <Avatar>
                              <LocalOffer color="primary" />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={promo.description}
                            secondary={promo.name}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() =>
                                this.handlePromotionUpdate(
                                  { pharmacyPromotion: promo._id },
                                  'delete'
                                )
                              }
                            >
                              <Delete />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </Fragment>
                    ))}
                  </List>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper className={classes.paper}>
                <PharmacyPasswordEditForm />
              </Paper>
            </Grid>
          </Fragment>
        )}
      </Grid>
    );
  }
}

const selector = formValueSelector('add-pharmacy-promo-form');

function mapStateToProps(state) {
  return {
    auth: state.auth,
    promoList: state.pharmacyPromoList,
    selectedPromotion: selector(state, 'pharmacyPromotion'),
  };
}

PharmacyProfile.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  promoList: PropTypes.array.isRequired,
  selectedPromotion: PropTypes.string,
  fetchUser: PropTypes.func.isRequired,
};

PromotionField.propTypes = {
  input: PropTypes.object.isRequired,
  promoArray: PropTypes.array.isRequired,
};

const ConnectedPharmacyProfile = connect(
  mapStateToProps,
  actions
)(withRouter(PharmacyProfile));

const PharmacyProfileRedux = reduxForm({
  // validate,
  form: 'add-pharmacy-promo-form',
})(ConnectedPharmacyProfile);

export default withStyles(styles)(PharmacyProfileRedux);
