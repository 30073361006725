import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Typography, Zoom, withStyles } from '@material-ui/core';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import * as actions from '../../../actions';

const styles = () => ({
  container: {
    marginTop: '3%',
  },
  reportButton: {
    margin: '10px 0px',
  },
});

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const previousMonthName = () => {
  const date = new Date();
  const monthName =
    date.getMonth() === 0 ? months[11] : months[date.getMonth() - 1];
  return monthName;
};
class MonthlyDeliveriesCard extends Component {
  renderDeliveriesAnimation() {
    let delay = 0;
    const today = new Date();
    const previousMonth = () =>
      today.getMonth() === 0 ? 11 : today.getMonth() - 1;
    const deliveries = this.props.deliveries?.items ?? [];
    const previousMonthDeliveries = deliveries.filter((item) => {
      const month = new Date(item.date).getMonth();
      return month === previousMonth();
    });
    let numbers = `${previousMonthDeliveries.length}`;
    numbers = numbers.split('');
    const numberAnimation = numbers.map((number, index) => {
      delay += 100;
      return (
        <Zoom
          in={true}
          timeout={500}
          style={{ transitionDelay: `${delay}ms` }}
          key={index}
        >
          <Grid item>
            <Typography color="primary" variant="h2" align="center">
              {number}
            </Typography>
          </Grid>
        </Zoom>
      );
    });
    return numberAnimation;
  }

  renderDeliveryPercentge() {
    const today = new Date();
    const previousMonth = () =>
      today.getMonth() === 0 ? 11 : today.getMonth() - 1;
    const controlMonth = previousMonth() - 1;
    const totalDeliveries = this.props.deliveries?.items ?? [];
    const previousMonthDeliveries = totalDeliveries.filter((item) => {
      const month = new Date(item.date).getMonth();
      return month === previousMonth();
    });
    const controlMonthDeliveries = totalDeliveries.filter((item) => {
      const month = new Date(item.date).getMonth();
      return month === controlMonth;
    });
    const difference =
      previousMonthDeliveries.length - controlMonthDeliveries.length;
    const percentage = () => {
      if (difference !== previousMonthDeliveries.length) {
        const result =
          ((previousMonthDeliveries.length - controlMonthDeliveries.length) /
            controlMonthDeliveries.length) *
          100;
        return result > 0
          ? `${result.toFixed(1)}%`
          : `${(result * -1).toFixed(1)}%`;
      }
      return 'Absolute growth.';
    };
    return (
      <Grid item container alignItems="center" justify="center">
        {difference > 0 ? (
          <ArrowDropUp style={{ fontSize: 40, color: 'green' }} />
        ) : (
          <ArrowDropDown style={{ fontSize: 40, color: 'red' }} />
        )}
        <Typography
          align="center"
          style={difference > 0 ? { color: 'green' } : { color: 'red' }}
        >
          {this.props.deliveries ? percentage() : '0%'}
        </Typography>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.container} spacing={1}>
        <Grid item container justify="center">
          <Grid item xs={12} container alignItems="center" justify="center">
            <Typography variant="button" color="primary" align="center">
              {previousMonthName()}
            </Typography>
          </Grid>
          {this.renderDeliveriesAnimation()}
        </Grid>
        <Grid item container alignItems="center" justify="center">
          {this.renderDeliveryPercentge()}
        </Grid>
        {/* <Grid item xs={12} container justify='center'>
          <Button
            className={classes.reportButton}
            variant='outlined'
            color='primary'
            size='small'
          >
            View Report
          </Button>
        </Grid> */}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    deliveries: state.deliveriesList,
  };
}

MonthlyDeliveriesCard.propTypes = {
  auth: PropTypes.object.isRequired,
  deliveries: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

const ConnectedMonthlyDeliveriesCard = connect(
  mapStateToProps,
  actions
)(withRouter(MonthlyDeliveriesCard));

export default withStyles(styles)(ConnectedMonthlyDeliveriesCard);
