import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  OutlinedInput,
  Slide,
  Badge,
  Tooltip,
  Typography,
  withStyles,
  CssBaseline,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import {
  Assignment,
  Chat,
  Description,
  ExpandMore,
  FirstPage,
  InsertDriveFile,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  LocalPharmacy,
} from '@material-ui/icons';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../actions';
import AppointmentFiles from '../../dialogs/AppointmentFiles';
import PdfRendering from '../pdfRendering/PdfRendering';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  layoutPdf: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 700,
    },
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
    float: 'right',
  },
  spacing: {
    marginBottom: 15,
  },
  center: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
  },
  resize: {
    fontSize: 50,
  },
  textBottomSpacing: {
    marginBottom: 20,
  },
});

const getBadgeColor = (props, theme) => {
  if (props.hasConsentForms && props.unsigned) {
    return theme.palette.error[500];
  }
  if (props.hasConsentForms && !props.unsigned) {
    return 'mediumseagreen';
  }
  return 'slategray';
};

const StyledBadge = withStyles((theme) => ({
  badge: (props) => ({
    backgroundColor: getBadgeColor(props, theme),
    color: 'white',
  }),
}))(Badge);

const iconColours = {
  doctorNotes: '#317178',
  textChat: '#3688c7',
  prescription: '#504cc4',
  schoolSickNote: 'primary',
  generalSickNote: 'primary',
  files: '#8243bd ',
};

class AppointmentHistory extends Component {
  constructor(props) {
    super(props);

    this.perPage = 10;

    this.state = {
      sortOrder: 'desc',
      openPdf: false,
      pdfData: {},
      rxData: {},
      expandedPanel: false,
      openFilesDialog: false,
      selectedFiles: [],
    };
  }

  async componentDidMount() {
    if (this.props.selectedUser && this.props.selectedUser._id) {
      this.props.getAppointmentHistory(this.props.selectedUser._id);
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedUser !== this.props.selectedUser) {
      this.props.getAppointmentHistory(this.props.selectedUser._id);
    }
  }

  nextPage = () => {
    const { selectedUser } = this.props;
    const { page } = this.props.appointments;
    const sort = this.state.sortOrder;
    this.props.getAppointmentHistory(selectedUser._id, page + 1, sort);
  };

  previousPage = () => {
    const { selectedUser } = this.props;
    const { page } = this.props.appointments;
    const prevPage = Math.max(1, page - 1); // Ensure page is not less than 1
    const sort = this.state.sortOrder;
    this.props.getAppointmentHistory(selectedUser._id, prevPage, sort);
  };

  firstPage = () => {
    const { selectedUser } = this.props;
    const sort = this.state.sortOrder;
    this.props.getAppointmentHistory(selectedUser._id, 1, sort);
  };

  lastPage = () => {
    const { selectedUser } = this.props;
    const lastPage = this.props.appointments.totalPages || 1;
    const sort = this.state.sortOrder;
    this.props.getAppointmentHistory(selectedUser._id, lastPage, sort);
  };

  setSortOrder = (order) => {
    this.setState({
      sortOrder: order,
    });
    const { selectedUser } = this.props;
    this.props.getAppointmentHistory(selectedUser._id, 1, order);
  };

  calculatePagination = (page) => {
    const { appointments } = this.props;
    const limit = appointments?.limit || this.perPage;
    const totalItems = appointments?.totalItems || 0;
    const lastPage = appointments?.totalPages || 1;

    return {
      startItem: ((page || 1) - 1) * limit + 1,
      endItem: Math.min(page * limit, totalItems),
      totalItems,
      lastPage,
      limit,
    };
  };

  // eslint-disable-next-line class-methods-use-this
  downloadPDF = async (path) => {
    const response = await axios.get(path, {
      responseType: 'blob',
    });
    if (response.data) {
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = path.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  downloadChatPDF = async (chatId) => {
    const res = await axios.get(`/api/chat-history/${chatId}`);
    if (res.data) {
      this.downloadPDF(res.data.documentSrc);
    }
  };

  handleOpenFilesDialog = (files) => {
    this.setState({
      openFilesDialog: true,
      selectedFiles: files,
    });
  };

  renderRxButton = (rxArray) => {
    const hasConsentForms = rxArray.some((rx) => rx.consentForms.length > 0);
    const hasUnsignedConsent = rxArray.some(
      (rx) => rx.consentForms.length > 0 && !rx.isConsentSigned
    );
    return (
      <Tooltip title="Prescriptions">
        <IconButton
          onClick={() =>
            this.setState({
              openPdf: true,
              rxData: rxArray,
            })
          }
        >
          <StyledBadge
            badgeContent={rxArray.length}
            invisible={false}
            hasConsentForms={hasConsentForms}
            unsigned={hasUnsignedConsent}
          >
            <LocalPharmacy style={{ color: iconColours.prescription }} />
          </StyledBadge>
        </IconButton>
      </Tooltip>
    );
  };

  renderAppointments = () => {
    const { appointments } = this.props;
    const { expandedPanel } = this.state;

    if (appointments?.items?.length === 0) {
      return (
        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{
            marginTop: 20,
          }}
        >
          <Typography textAlign="center" variant="h5" color="primary">
            No appointment records.
          </Typography>
        </Grid>
      );
    }

    return appointments.items.map((appt) => {
      let rxConsent = false;
      let consentSigned = true;
      if (appt && Array.isArray(appt.prescriptions)) {
        appt.prescriptions.forEach((rx) => {
          if (rx.consentForms.length > 0) {
            rxConsent = true;
            if (!rx.isConsentSigned) {
              consentSigned = false;
            }
          }
        });
      }
      return (
        <Accordion
          key={appt._id}
          expanded={expandedPanel === appt._id}
          onChange={(_, isExpanded) =>
            this.setState({ expandedPanel: isExpanded ? appt._id : false })
          }
        >
          <AccordionSummary
            style={{ backgroundColor: '#ebebeb' }}
            expandIcon={<ExpandMore />}
          >
            <Grid container direction="row" spacing={1}>
              <Grid item container xs={12} sm={6} alignItems="center">
                {(appt.patientLeftWaitingRoom || !appt.patientConnected) &&
                this.props.appointmentInQueueId !== appt._id ? (
                  <Typography variant="button" color="error">
                    MISSED CALL - DATE:{' '}
                    {new Date(appt.date).toLocaleDateString('en-CA')}
                  </Typography>
                ) : (
                  <Typography variant="button" color="primary">
                    Appointment Date:{' '}
                    {new Date(appt.date).toLocaleDateString('en-CA')}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={6}
                direction="row"
                spacing={1}
                justify="flex-end"
              >
                <Grid item>
                  {this.props.appointmentInQueueId === appt._id && (
                    <Typography variant="caption" color="error">
                      CURRENT APPOINTMENT
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <StyledBadge
                    badgeContent=""
                    variant="dot"
                    invisible={!rxConsent}
                    hasConsentForms={rxConsent}
                    unsigned={!consentSigned}
                  >
                    <Typography variant="button" color="primary">
                      ID: {appt._id.substring(19, 24)}
                    </Typography>
                  </StyledBadge>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails style={{ backgroundColor: '#fcfcfc' }}>
            <Grid container>
              <Grid item xs={12} style={{ marginLeft: '15px' }}>
                {appt.ohipAppointment && (
                  <Typography variant="h6" color="primary">
                    OHIP Appointment
                  </Typography>
                )}

                {appt.doctor && (
                  <Typography variant="body2">
                    <strong> Doctor:</strong> Dr. {appt.doctor.name.first}{' '}
                    {appt.doctor.name.last}
                  </Typography>
                )}

                {appt.ohipAppointment && (
                  <Typography variant="body2">
                    <strong>OHIP Number:</strong> {appt.ohipNumber}
                  </Typography>
                )}

                <Typography variant="body2">
                  <strong>Reason for Visit:</strong> {appt.shortReason}
                </Typography>
                {appt.symptoms && appt.symptoms.length > 0 && (
                  <Typography variant="body2">
                    <strong> Symptoms:</strong> {appt.symptoms.join(', ')}
                  </Typography>
                )}
                {appt.temperature && (
                  <Typography variant="body2">
                    <strong> Temperature:</strong> {appt.temperature}&#176;C
                  </Typography>
                )}
                {appt.heartRate && (
                  <Typography variant="body2">
                    <strong> Heart Rate:</strong> {appt.heartRate}
                  </Typography>
                )}
                {appt.bloodPressure && (
                  <Typography variant="body2">
                    <strong> Blood Pressure:</strong> {appt.bloodPressure}
                  </Typography>
                )}
              </Grid>
              {appt.doctor && appt.doctorNotesFinalized && (
                <Tooltip title="Doctor Notes">
                  <IconButton
                    onClick={() =>
                      this.downloadPDF(appt.doctorNotesDocumentSrc)
                    }
                  >
                    <Assignment style={{ color: iconColours.doctorNotes }} />
                  </IconButton>
                </Tooltip>
              )}
              {appt.savedTextChat && (
                <Tooltip title="Text Chat">
                  <IconButton>
                    <Chat
                      style={{ color: iconColours.textChat }}
                      onClick={() => {
                        this.downloadChatPDF(appt.savedTextChat);
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {appt.prescriptions &&
                appt.prescriptions.length > 0 &&
                this.renderRxButton(appt.prescriptions)}
              {appt.fileList && appt.fileList.length > 0 && (
                <Tooltip title="Files">
                  <IconButton
                    onClick={() => this.handleOpenFilesDialog(appt.fileList)}
                  >
                    <InsertDriveFile style={{ color: iconColours.files }} />
                  </IconButton>
                </Tooltip>
              )}
              {appt.schoolSicknote !== undefined && (
                // <PdfDialog
                //   data={appt.schoolSicknote.schoolSickNoteValues}
                //   type={'Sick Note'}
                // />
                <IconButton
                  onClick={() =>
                    this.setState({
                      openPdf: true,
                      pdfData: appt.schoolSicknote.schoolSickNoteValues,
                    })
                  }
                >
                  <Description color="primary" />
                </IconButton>
              )}
              {appt.generalSicknote !== undefined && (
                <IconButton
                  onClick={() =>
                    this.setState({
                      openPdf: true,
                      pdfData: appt.generalSicknote.generalSickNoteValues,
                    })
                  }
                >
                  <Description color="primary" />
                </IconButton>
              )}
              {appt.patientLeftWaitingRoom && (
                <Typography variant="caption" color="error">
                  {appt.patientWasPicked
                    ? `NOTE: Patient left appointment.`
                    : `NOTE: Patient left waiting room.`}
                </Typography>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  render() {
    const { classes, appointments, selectedUser } = this.props;

    const { page } = this.props.appointments || {};
    const { startItem, endItem, totalItems, lastPage } =
      this.calculatePagination(page);

    return !this.state.openPdf ? (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Grid
              container
              spacing={1}
              style={{
                margin: 0,
                width: '100%',
              }}
              direction="row"
            >
              <Grid item xs={12} style={{ marginTop: '5%' }}>
                <Typography
                  variant="h5"
                  className={classes.spacing}
                  color="primary"
                >
                  Your Appointment History
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  className={classes.spacing}
                  color="primary"
                >
                  Patient:{' '}
                  <span style={{ fontSize: '22px' }}>
                    {selectedUser.fullName}
                  </span>
                </Typography>
              </Grid>
              <br />
              <Grid item xs={12} className={classes.textBottomSpacing}>
                <Typography variant="body1">
                  {`You can see all information from your previous appointments
                  below, including your prescriptions and the doctor's notes.
                  Just click to expand the tab.`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={6} sm={4}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel htmlFor={'order'}>Order by</InputLabel>
                      <Select
                        id="order"
                        input={<OutlinedInput label="Order by" />}
                        InputProps={{ classes: { input: classes.resize } }}
                        value={this.state.sortOrder}
                        onChange={(event) => {
                          this.setSortOrder(event.target.value);
                        }}
                      >
                        {[
                          { label: 'Latest', value: 'desc' },
                          { label: 'Oldest', value: 'asc' },
                        ].map((order) => (
                          <MenuItem value={order.value} key={order.label}>
                            {order.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="caption" className={classes.center}>
                      {`${startItem} - ${endItem} of ${totalItems}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={1}>
                    <IconButton onClick={this.firstPage} disabled={page === 1}>
                      <FirstPage />
                    </IconButton>
                  </Grid>
                  <Grid item xs={3} sm={1}>
                    <IconButton
                      onClick={this.previousPage}
                      disabled={page === 1}
                    >
                      <KeyboardArrowLeft />
                    </IconButton>
                  </Grid>

                  <Grid item xs={3} sm={1}>
                    <IconButton
                      onClick={this.nextPage}
                      disabled={page === lastPage}
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </Grid>
                  <Grid item xs={3} sm={1}>
                    <IconButton
                      onClick={this.lastPage}
                      disabled={page === lastPage}
                    >
                      <LastPage />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {!appointments ? (
                  <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    alignContent="center"
                    style={{
                      height: '200px',
                    }}
                  >
                    <CircularProgress size={40} color="primary" />
                  </Grid>
                ) : (
                  this.renderAppointments()
                )}
              </Grid>
            </Grid>
          </main>
        </Slide>
        {this.state.openFilesDialog && (
          <AppointmentFiles
            open={this.state.openFilesDialog}
            onClose={() =>
              this.setState({ openFilesDialog: false, selectedFiles: [] })
            }
            files={this.state.selectedFiles}
          />
        )}
      </Fragment>
    ) : (
      <Fragment>
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Grid item>
              <PdfRendering
                rxData={this.state.rxData}
                closeDocument={() => this.setState({ openPdf: false })}
                addPharmacy={this.props.pharmacyPage}
              />
            </Grid>
          </main>
        </Slide>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    form: state.form,
    auth: state.auth,
    appointments: state.appointments,
    selectedUser: state.selectedUser,
  };
}

AppointmentHistory.propTypes = {
  form: PropTypes.object,
  auth: PropTypes.object,
  selectedUser: PropTypes.object,
  appointments: PropTypes.shape({
    items: PropTypes.array,
    totalItems: PropTypes.number,
    totalPages: PropTypes.number,
    page: PropTypes.number,
    limit: PropTypes.number,
  }),
  getAppointmentHistory: PropTypes.func,
  pharmacyPage: PropTypes.func,
  classes: PropTypes.object,
  appointmentInQueueId: PropTypes.string,
};

const ConnectedAppointmentHistory = connect(
  mapStateToProps,
  actions
)(AppointmentHistory);

export default withStyles(styles)(ConnectedAppointmentHistory);
