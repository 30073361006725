import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CssBaseline,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { AccountBox, Settings } from '@material-ui/icons';
import AppointmentsIcon from '@material-ui/icons/MeetingRoom';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import * as actions from '../../../actions';
import { doctorSocket } from '../../../api/doctor-ws';
import DoctorSignature from '../DoctorSignature';
import { AvailableDoctorCount } from './AvailableDoctorsList';
import DoctorAppointmentHistory from './DoctorAppointmentHistory';
import DoctorProfile from './DoctorProfile';
import DoctorProfileDashboard from './DoctorProfileDashboard';
import DrSettings from './DrSettings';
import EditDoctorNotes from './EditDoctorNotes';
import FinishAppointment from './FinishAppointment';
import QueueDisplay from './QueueDisplay';
import RequestPatientButton from './RequestPatientButton';
import TextChatTranscript from './TextChatTranscript';
import { DoctorListContext } from '../../../contexts/DoctorListContext';

const styles = (theme) => ({
  layout: {
    overflow: 'hidden',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: theme.spacing.unit * 6,
      marginRight: theme.spacing.unit * 6,
    },
    maxWidth: 850,
  },

  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },

  toolbarPaper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '7px',
    overflow: 'auto',
  },

  welcome: {
    float: 'left',
    marginTop: 10,
  },

  iconButton: {
    height: '25%',
    borderRadius: 0,
    width: '100%',
  },

  renderArea: {
    height: '700px',
    overflowY: 'auto',
    padding: 10,
  },

  button: {
    '&:disabled': {
      backgroundColor: '#ffffff',
    },
  },
  link: {
    color: '#caebf2',
    '&:visited': {
      color: '#caebf2',
    },
  },
});

class DoctorDashboard extends Component {
  static contextType = DoctorListContext;

  constructor(props) {
    super(props);
    this.state = {
      socket: doctorSocket,
      disableRequestButton: false,
      emptyQueue: true,
      numPatients: 0,
      numDoctors: 0,
      open: false,
      close: false,
      toolbarPage: 'DoctorAppointmentHistory',
      disableToolbar: false,
      openDoctorList: false,
      openDoctorInfo: false,
      menuAnchor: null,
      doctorList: [],
      doctorsInSession: [],
      openSnackbar: false,
      editNoteParams: {},
      chatHistoryId: '',
      doctorActiveSessions: [],
    };

    this.state.socket.on(this.props.auth._id, async (response) => {
      if (response.logout) {
        this.props.logout(
          this.props.auth.role,
          null,
          this.props.history,
          null,
          null,
          true
        );
      }
    });

    props.fetchData();
  }

  async componentDidMount() {
    await axios.get('/api/doctor-in-session').then((res) => {
      if (res.data.length > 0)
        this.setState({ doctorActiveSessions: res.data });
    });

    const doctorSessions = this.context.inSession.filter(
      (session) => session._id === this.props.auth._id
    );

    if (doctorSessions.length > 0) {
      this.setState({ doctorActiveSessions: doctorSessions });
    }

    this.props.fetchUser();
  }

  handleClose = () => {
    this.setState({ openSnackbar: false });
  };

  openDoctorListInfo = () => {
    this.setState({ openDoctorInfo: true });
  };

  mainPaper() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper} style={{ backgroundColor: '#0f192c' }}>
        <Grid container direction="row">
          <Grid item xs={12} sm={8}>
            <Typography
              variant="h6"
              color="secondary"
              style={{ marginBottom: '2%' }}
            >
              Dashboard
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={4}>
            <Grid item xs={12}>
              <QueueDisplay />
            </Grid>
            <Grid item xs={12}>
              <AvailableDoctorCount className={classes.link} />
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ margin: '15px 0px' }}>
            {this.props.auth.signatureConfirmed && this.props.auth.CPSO && (
              <Typography variant="body1" color={'secondary'}>
                Welcome, Dr. {this.props.auth.name.last}. Click below to start
                seeing patients.
              </Typography>
            )}
            {this.props.auth.signatureConfirmed || (
              <Typography
                variant="body1"
                color={'secondary'}
                style={{ marginBottom: '2%' }}
              >
                Add your signature{' '}
                <Link
                  to="#"
                  onClick={() =>
                    this.setState({ toolbarPage: 'EditSignature' })
                  }
                  style={{
                    color: 'white',
                  }}
                >
                  here
                </Link>
                . Your signature will be added to all your appointments.
              </Typography>
            )}
            {this.props.auth.CPSO || (
              <Typography
                variant="body1"
                color={'secondary'}
                style={{ marginBottom: '2%' }}
              >
                To see patients you must add your CPSO number{' '}
                <Link
                  to="#"
                  onClick={() => this.setState({ toolbarPage: 'EditProfile' })}
                  style={{
                    color: 'white',
                  }}
                >
                  here
                </Link>
                .
              </Typography>
            )}
          </Grid>
          <Grid item container justify="space-between" xs={12}>
            <Grid item>
              <RequestPatientButton
                className={classes.button}
                activeSessions={this.state.doctorActiveSessions.length}
              />
            </Grid>
            {this.state.doctorActiveSessions.length > 0 && (
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() =>
                    this.props.history.push(
                      `/appointmentroomdr/${this.state.doctorActiveSessions[0]._id}`
                    )
                  }
                >
                  Back to session
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  toolbar() {
    const { classes } = this.props;
    return (
      <Grid
        item
        xs={2}
        sm={1}
        style={{
          backgroundColor: '#0f192c',
          borderRadius: '7px 0px 0px 7px ',
        }}
      >
        <Tooltip title="Appointment History" placement="left">
          <IconButton
            className={classes.iconButton}
            color="secondary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => {
              this.setState({ toolbarPage: 'DoctorAppointmentHistory' });
            }}
          >
            <AppointmentsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="About You" placement="left">
          <IconButton
            className={classes.iconButton}
            color="secondary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => this.setState({ toolbarPage: 'AboutDr' })}
          >
            <AccountBox />
          </IconButton>
        </Tooltip>
        <Tooltip title="Settings" placement="left">
          <IconButton
            className={classes.iconButton}
            color="secondary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => this.setState({ toolbarPage: 'Settings' })}
          >
            <Settings />
          </IconButton>
        </Tooltip>
      </Grid>
    );
  }

  render() {
    const { handleSubmit, classes } = this.props;
    const { toolbarPage, openSnackbar } = this.state;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          {this.mainPaper()}
          <br />
          <Paper className={classes.toolbarPaper}>
            <Grid
              container
              direction="row"
              style={{
                margin: 0,
                width: '100%',
              }}
            >
              {this.toolbar()}
              <Grid item xs={10} sm={11}>
                <form
                  id="dr_info_update"
                  onSubmit={handleSubmit(async (values) => {
                    const res = await this.props.editProfile(values);
                    if (res.data.success) {
                      this.setState({
                        toolbarPage: 'AboutDr',
                        disableToolbar: false,
                      });
                    }
                  })}
                >
                  {toolbarPage === 'AboutDr' && (
                    <Grid item className={classes.renderArea}>
                      <DoctorProfile
                        auth={this.props.auth}
                        changePage={(page) =>
                          this.setState({ toolbarPage: page })
                        }
                        disableToolbar={() =>
                          this.setState({ disableToolbar: true })
                        }
                      />
                    </Grid>
                  )}
                  {toolbarPage === 'EditProfile' && (
                    <Grid item className={classes.renderArea}>
                      <DoctorProfileDashboard
                        auth={this.props.auth}
                        initialValues={{
                          DrDashboard: {
                            country_code: '+1',
                          },
                        }}
                        changePage={() =>
                          this.setState({ toolbarPage: 'AboutDr' })
                        }
                        disableToolbar={() =>
                          this.setState({ disableToolbar: false })
                        }
                      />
                    </Grid>
                  )}
                  {toolbarPage === 'DoctorAppointmentHistory' && (
                    <Grid item className={classes.renderArea}>
                      <DoctorAppointmentHistory
                        disableToolbar={(value) =>
                          this.setState({ disableToolbar: value })
                        }
                        openEditNote={(appointment) => {
                          this.setState({
                            toolbarPage: 'EditDoctorNotes',
                            editNoteParams: appointment,
                          });
                        }}
                        openFinishAppointment={(appointment) => {
                          this.setState({
                            toolbarPage: 'FinishAppointment',
                            editNoteParams: appointment,
                          });
                        }}
                        openChatHistory={(chatId) => {
                          this.setState({
                            toolbarPage: 'ChatHistory',
                            chatHistoryId: chatId,
                          });
                        }}
                      />
                    </Grid>
                  )}
                  {toolbarPage === 'Settings' && (
                    <Grid item className={classes.renderArea}>
                      <DrSettings
                        disableToolbar={(value) =>
                          this.setState({ disableToolbar: value })
                        }
                        exitPage={() =>
                          this.setState({
                            toolbarPage: 'DoctorAppointmentHistory',
                          })
                        }
                      />
                    </Grid>
                  )}
                  {toolbarPage === 'EditSignature' && (
                    <Grid item className={classes.renderArea}>
                      <DoctorSignature
                        disableToolbar={() =>
                          this.setState({ disableToolbar: true })
                        }
                        exitPage={() =>
                          this.setState({
                            toolbarPage: 'AboutDr',
                          })
                        }
                      />
                    </Grid>
                  )}
                  {toolbarPage === 'ChatHistory' && (
                    <Grid item className={classes.renderArea}>
                      <TextChatTranscript
                        disableToolbar={(value) =>
                          this.setState({ disableToolbar: value })
                        }
                        exitPage={() =>
                          this.setState({
                            toolbarPage: 'DoctorAppointmentHistory',
                          })
                        }
                        textChatId={this.state.chatHistoryId}
                      />
                    </Grid>
                  )}
                </form>
                {toolbarPage === 'FinishAppointment' && (
                  <Grid item className={classes.renderArea}>
                    <FinishAppointment
                      appointment={this.state.editNoteParams}
                      disableToolbar={(value) =>
                        this.setState({ disableToolbar: value })
                      }
                      exitPage={() =>
                        this.setState({
                          toolbarPage: 'DoctorAppointmentHistory',
                        })
                      }
                    />
                  </Grid>
                )}
                {toolbarPage === 'EditDoctorNotes' && (
                  <Grid item className={classes.renderArea}>
                    <EditDoctorNotes
                      disableToolbar={(value) =>
                        this.setState({ disableToolbar: value })
                      }
                      exitPage={() =>
                        this.setState({
                          toolbarPage: 'DoctorAppointmentHistory',
                        })
                      }
                      editNoteParams={this.state.editNoteParams}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={openSnackbar}
              onClose={this.handleClose}
              autoHideDuration={3000}
              message="A patient has just arrived."
            />
          </Paper>
        </main>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    medicalData: state.medicalData,
    appointments: state.appointments,
    timerValues: state.timerValues,
  };
}

DoctorDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  editProfile: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

const ConnectedDoctorDashboard = connect(
  mapStateToProps,
  actions
)(withRouter(DoctorDashboard));

const DoctorDashboardRedux = reduxForm({
  form: 'drDashboardUpdateForm',
  destroyOnUnmount: false,
})(ConnectedDoctorDashboard);

export default withStyles(styles)(DoctorDashboardRedux);
