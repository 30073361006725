import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LoginRoot from '../login/LoginRoot';
import RegistrationForm from '../registration/RegistrationForm';
import ResetPassword from '../login/ResetPassword';
import DoctorLoginRoot from '../doctors/login/DoctorLoginRoot';
import PharmacyLoginForm from '../pharmacies/login/PharmacyLoginForm';
import CheckEmailPromptPage from '../registration/CheckEmailPromptPage';
import GuestAppointmentRoom from '../seeDoctor/GuestAppointmentRoom';
import EmailConfirmationMessage from '../registration/EmailConfirmationMessage';
import { REACT_APP_JAAS_APP_ID } from '../../constants';
import AccountTransferForm from '../registration/AccountTransferForm';
import ResetPasswordPharmacy from '../pharmacies/login/ResetPasswordPharmacy';
import TermsAndConditions from '../../legal/TermsAndConditions';
import PrivacyPolicy from '../../legal/PrivacyPolicy';

const MainRoutes = () => (
  <Switch>
    <Route exact={true} path="/registration/new" component={RegistrationForm} />
    <Route
      exact={true}
      path="/emailverification/:email"
      component={CheckEmailPromptPage}
    />
    <Route exact path="/doctors" component={DoctorLoginRoot} />
    <Route exact path="/pharmacy" component={PharmacyLoginForm} />
    <Route exact path="/login/:failure" component={LoginRoot} />
    <Route exact path="/password-reset/:token" component={ResetPassword} />
    <Route
      exact={true}
      path="/email-confirmation/:status?/:userId?"
      component={EmailConfirmationMessage}
    />
    <Route exact={true} path="/privacy-policy" component={PrivacyPolicy} />
    <Route
      exact={true}
      path="/terms-and-conditions"
      component={TermsAndConditions}
    />
    <Route
      exact={true}
      path="/account-transfer-form/:userId"
      component={AccountTransferForm}
    />
    <Route
      exact
      path="/password-reset/pharmacy/:token"
      component={ResetPasswordPharmacy}
    />
    <Route
      exact
      path={`/${REACT_APP_JAAS_APP_ID}/:room`}
      component={GuestAppointmentRoom}
    />
    <Route exact path="/*" component={LoginRoot} />
  </Switch>
);

export default MainRoutes;
