import axios from 'axios';
import getRecaptchaToken from '../../utils/getRecaptchaToken';

class LoginService {
  // eslint-disable-next-line class-methods-use-this
  async forgotPassword(email) {
    const recaptchaToken = await getRecaptchaToken('forgot_password');

    return axios
      .post('/api/auth/forgot-password', { email, recaptchaToken })
      .then((response) => response.data)
      .catch((error) => error.response.data);
  }

  // eslint-disable-next-line class-methods-use-this
  async forgotPharmacyPassword(email) {
    const recaptchaToken = await getRecaptchaToken('forgot_pharmacy_password');
    return axios
      .post('/api/auth/pharmacy/forgot-password', { email, recaptchaToken })
      .then((response) => response.data)
      .catch((error) => error.response.data);
  }
}

export default LoginService;
