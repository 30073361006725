import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import axios from 'axios';
import { createTextMask } from 'redux-form-input-masks';

import {
  withStyles,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
} from '@material-ui/core';
import { ArrowBackIos, CheckCircle, Cancel } from '@material-ui/icons';
import PathBuilder from '../../../api/media-path-builder';
import DropzoneInputOhip from '../../commonform/FileInputOhip';
import * as actions from '../../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 700,
    },
  },
  paper: {
    padding: '20px',
    borderRadius: '7px',
  },
  typography: {
    marginTop: 15,
  },
  redCardSection: {
    marginTop: 10,
  },
  btnSection: {
    marginTop: 10,
  },
});

const customOhipNumberMask = {
  9: {
    regExp: /[0-9]/,
  },
  A: {
    regExp: /[A-Za-z]/,
    transform: (char) => char.toUpperCase(),
  },
};

const ohipNumberMask = createTextMask({
  pattern: '9999-999-999-AA',
  guide: false,
  allowEmpty: false,
  stripMask: false,
  maskDefinitions: customOhipNumberMask,
});

const OhipNumberField = ({ input, name, type, meta: { touched, error } }) => (
  <TextField
    {...input}
    fullWidth
    variant="outlined"
    type={type}
    id={name}
    error={touched && error}
    helperText={(touched && error) || '####-###-###-AZ'}
  />
);

class UploadOhipCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redOhipCardDialog: false,
      disableUploadButton: true,
      ohipVerified: false,
      ohipPassed: false,
      verificationMsg: '',
      loading: false,
      imageUploaded: false,
      imageFile: null,
    };
  }

  uploadCardImage = async () => {
    this.setState({ backdrop: true });
    const { imageFile } = this.state;
    const formData = new FormData();
    let newFileName = `${imageFile.name.replace(/(_| )/g, '-')}`;

    // detect length of file name and truncate if necessary, but keep extension
    if (newFileName.length > 50) {
      newFileName = `${newFileName.slice(0, 50)}${newFileName.slice(
        newFileName.lastIndexOf('.')
      )}`;
    }

    const newFile = new File([imageFile], newFileName, {
      type: imageFile.type,
    });
    const path = PathBuilder.patientHealthCardFilePath(
      this.props.selectedUser._id,
      newFileName
    );

    formData.append('file', newFile);
    formData.append('role', this.props.selectedUser.role);
    return axios.post(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  verifyOhip = async () => {
    if (this.props.ohipNumber) {
      axios
        .post('/api/profile/patient/ohip-card-validation', {
          userId: this.props.auth._id,
          softCheckOhip: true,
          healthCardNumber: this.props.ohipNumber,
          backdrop: true,
        })
        .then((res) => {
          if (res.data && res.data.isOhipValid) {
            this.setState({
              ohipVerified: true,
              ohipPassed: true,
              verificationMsg: res.data.message,
              loading: true,
            });
            return this.uploadCardImage();
          }
          if (res.data && !res.data.isOhipValid) {
            this.setState({
              ohipVerified: true,
              verificationMsg: res.data.message,
              ohipPassed: false,
            });
            return res;
          }
          return res;
        })
        .then((response) => {
          if (response.data && response.data.newFile) {
            this.props.editProfile({
              id: this.props.selectedUser._id,
              medicalProfile: {
                ohipCardImage: response.data.newFile,
                ohipNumber: this.props.ohipNumber,
                ohipExpiration: this.props.ohipExpiration,
              },
            });
            this.setState({
              ohipInfo: response.data,
              imageUploaded: true,
            });
            this.props.skip();
          } else if (response.data && response.data.error) {
            this.setState({ ohipVerified: true, ohipPassed: false });
          }
        });
    }
  };
  // eslint-disable-next-line class-methods-use-this
  renderOhipNumber = () => (
    <Field name="ohipNumber" component={OhipNumberField} {...ohipNumberMask} />
  );

  renderSubtitle = () => {
    const { ohipVerified, ohipPassed, imageUploaded } = this.state;
    let message = null;

    if (!ohipVerified && !imageUploaded) {
      message = 'Picture of your OHIP card (front).';
    } else if (ohipVerified && ohipPassed && !imageUploaded) {
      message = 'Uploading image...';
    }
    return message;
  };

  render() {
    const { classes } = this.props;
    const { ohipVerified, ohipPassed, imageUploaded, verificationMsg } =
      this.state;
    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                Upload your OHIP card
              </Typography>
            </Grid>
            {!ohipVerified ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    OHIP Number{' '}
                    <span
                      style={{
                        color: 'green',
                        fontSize: '13px',
                        margin: '4px',
                      }}
                    >
                      (Green Health card)
                    </span>
                  </Typography>
                  {this.renderOhipNumber()}
                </Grid>
              </>
            ) : (
              <Grid item container xs={12}>
                <Grid item container xs={1} alignItems="center">
                  {ohipPassed ? (
                    <CheckCircle style={{ color: 'green' }} />
                  ) : (
                    <Cancel style={{ color: 'red' }} />
                  )}
                </Grid>
                <Typography
                  style={{ color: ohipPassed ? 'green' : 'red' }}
                  variant="h6"
                >
                  {verificationMsg}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography>{this.renderSubtitle()}</Typography>
            </Grid>
            {!imageUploaded ? (
              <DropzoneInputOhip
                setImageFile={(file) => this.setState({ imageFile: file })}
                loading={this.state.loading}
              />
            ) : (
              <Grid item container xs={12}>
                <Grid item container xs={1} alignItems="center">
                  <CheckCircle style={{ color: 'green' }} />
                </Grid>
                <Typography style={{ color: 'green' }} variant="h6">
                  Image uploaded successfully.
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              container
              justify="center"
              className={classes.redCardSection}
            >
              <Button
                variant="outlined"
                onClick={() => this.setState({ redOhipCardDialog: true })}
                style={{
                  textTransform: 'none',
                  color: '#ef4242',
                  borderColor: '#ef4242',
                  backgroundColor: 'white',
                }}
              >
                Click here if you have a Red & White Card
              </Button>
            </Grid>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              className={classes.btnSection}
            >
              <Grid item xs={2}>
                <Button
                  startIcon={<ArrowBackIos />}
                  variant="outlined"
                  color="primary"
                  disabled={this.state.loading}
                  onClick={() => this.props.previousStep()}
                >
                  Back
                </Button>
              </Grid>
              <Grid item container xs={10} justify="flex-end" spacing={1}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={this.state.loading}
                    onClick={() => {
                      this.props.skip();
                    }}
                  >
                    Skip
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.verifyOhip}
                    disabled={
                      this.state.loading ||
                      !this.props.ohipNumber ||
                      !this.state.imageFile
                    }
                  >
                    Upload
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Dialog
              open={this.state.redOhipCardDialog}
              onClose={() => this.setState({ redOhipCardDialog: false })}
            >
              <DialogTitle>Red and White OHIP Card?</DialogTitle>
              <DialogContent>
                <Typography>
                  Please call us at <b>1-833-762-7333</b> for help adding the
                  card to your profile.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ redOhipCardDialog: false })}
                  variant="contained"
                  color="primary"
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Paper>
      </main>
    );
  }
}

const selector = formValueSelector('wizard');

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedUser: state.selectedUser,
    ohipNumber: selector(state, 'ohipNumber'),
    ohipExpiration: selector(state, 'ohipExpiration'),
  };
}

UploadOhipCard.propTypes = {
  skip: PropTypes.func,
  classes: PropTypes.object,
  auth: PropTypes.object,
  selectedUser: PropTypes.object,
  ohipNumber: PropTypes.string,
  ohipExpiration: PropTypes.string,
  editProfile: PropTypes.func,
  previousStep: PropTypes.func,
};

OhipNumberField.propTypes = {
  input: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
};

const ConnectedUploadOhipCard = connect(
  mapStateToProps,
  actions
)(UploadOhipCard);

const UploadOhipCardRedux = reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(ConnectedUploadOhipCard);

export default withStyles(styles)(UploadOhipCardRedux);
