import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, FormSection } from 'redux-form';
import {
  CssBaseline,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Grid,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Slide,
  OutlinedInput,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import {
  ExpandMore,
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@material-ui/icons';
import * as actions from '../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
    float: 'right',
  },
  spacing: {
    marginBottom: 15,
  },
  align: {
    marginRight: '45%',
  },
  noPadding: {
    padding: 0,
  },
  center: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
  },
  textBottomSpacing: {
    marginBottom: 20,
  },
});

class PaymentHistory extends Component {
  constructor(props) {
    super(props);

    this.perPage = 10;
    this.state = {
      sortOrder: 'desc',
      expandedPanel: null,
    };
  }

  componentDidMount() {
    this.props.getPaymentHistory();
  }

  nextPage = () => {
    const { page } = this.props.payments;
    const sort = this.state.sortOrder;
    this.props.getPaymentHistory(page + 1, sort);
  };

  previousPage = () => {
    const { page } = this.props.payments;
    const prevPage = Math.max(1, page - 1); // Ensure page is not less than 1
    const sort = this.state.sortOrder;
    this.props.getPaymentHistory(prevPage, sort);
  };

  firstPage = () => {
    const sort = this.state.sortOrder;
    this.props.getPaymentHistory(1, sort);
  };

  lastPage = () => {
    const lastPage = this.props.payments.totalPages || 1;
    const sort = this.state.sortOrder;
    this.props.getPaymentHistory(lastPage, sort);
  };

  setSortOrder = (order) => {
    this.setState({
      sortOrder: order,
    });
    this.props.getPaymentHistory(1, order);
  };

  calculatePagination = (page) => {
    const { payments } = this.props;
    const limit = payments?.limit || this.perPage;
    const totalItems = payments?.totalItems || 0;
    const lastPage = payments?.totalPages || 1;

    return {
      startItem: ((page || 1) - 1) * limit + 1,
      endItem: Math.min(page * limit, totalItems),
      totalItems,
      lastPage,
      limit,
    };
  };

  renderPayments = () => {
    const { payments, classes } = this.props;
    const { expandedPanel } = this.state;

    if (payments?.items?.length === 0) {
      return (
        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{
            marginTop: 20,
          }}
        >
          <Typography textAlign="center" variant="h5" color="primary">
            No payment records.
          </Typography>
        </Grid>
      );
    }

    return payments.items.map((payment) => {
      const purchaseDate = new Date(payment.date);
      const expirationDate = new Date(payment.date);
      expirationDate.setHours(
        expirationDate.getHours() + payment.durationInHours
      );
      return (
        <Accordion
          key={payment._id}
          expanded={expandedPanel === payment._id}
          onChange={(event, isExpanded) =>
            this.setState({ expandedPanel: isExpanded ? payment._id : false })
          }
        >
          <AccordionSummary
            style={{ backgroundColor: '#ebebeb' }}
            expandIcon={<ExpandMore />}
          >
            <Typography
              className={classes.align}
              variant="button"
              color="primary"
            >
              Payment Date: {new Date(payment.date).toLocaleDateString('en-CA')}
            </Typography>

            <Typography variant="button" color="primary">
              ID:
              {payment._id.substring(19, 24)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: '#fcfcfc' }}>
            <Grid item xs={12}>
              <Typography variant="body2" paragraph>
                <strong>Amount Paid:</strong> ${payment.amount}
              </Typography>
              <Typography variant="body2" paragraph>
                <strong>Item:</strong> {payment.paymentReason}
              </Typography>
              <Typography variant="body2" paragraph>
                <strong>Date of Purchase:</strong>{' '}
                {purchaseDate.toLocaleDateString()}
              </Typography>
              {payment.paymentReason === 'Standard Subscription' && (
                // content for Standard Subscription
                <>
                  {payment.recipient && (
                    <Typography variant="body2" paragraph>
                      <strong>Patient:</strong>{' '}
                      {`${payment.recipient.name.first} ${payment.recipient.name.last}`}
                    </Typography>
                  )}
                  <Typography variant="body2" paragraph>
                    <strong>Duration:</strong>{' '}
                    {payment.durationInHours <= 24
                      ? `${payment.durationInHours} hours`
                      : `${payment.durationInHours / 24} days`}
                  </Typography>
                  <Typography variant="body2" paragraph>
                    <strong>Ends:</strong> {expirationDate.toLocaleDateString()}{' '}
                    {expirationDate.toLocaleTimeString()}
                  </Typography>
                </>
              )}
              {payment.credits && (
                // content for everything else
                <Typography variant="body2" paragraph>
                  <strong>Credits:</strong> {payment.credits}
                </Typography>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  renderToolbar = () => {
    const { classes } = this.props;
    const { page } = this.props.payments || {};
    const { startItem, endItem, totalItems, lastPage } =
      this.calculatePagination(page);

    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={6} sm={4}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel htmlFor={'order'}>Order by</InputLabel>
            <Select
              id="order"
              input={<OutlinedInput label="Order by" />}
              InputProps={{ classes: { input: classes.resize } }}
              value={this.state.sortOrder}
              onChange={(event) => {
                this.setSortOrder(event.target.value);
              }}
            >
              {[
                { label: 'Oldest', value: 'asc' },
                { label: 'Latest', value: 'desc' },
              ].map((order) => (
                <MenuItem value={order.value} key={order.label}>
                  {order.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          style={{
            textAlign: 'center',
          }}
        >
          <Typography variant="caption" className={classes.center}>
            {`${startItem} - ${endItem} of ${totalItems}`}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={1}>
          <IconButton onClick={this.firstPage} disabled={page === 1}>
            <FirstPage />
          </IconButton>
        </Grid>
        <Grid item xs={3} sm={1}>
          <IconButton onClick={this.previousPage} disabled={page === 1}>
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>

        <Grid item xs={3} sm={1}>
          <IconButton onClick={this.nextPage} disabled={page === lastPage}>
            <KeyboardArrowRight />
          </IconButton>
        </Grid>
        <Grid item xs={3} sm={1}>
          <IconButton onClick={this.lastPage} disabled={page === lastPage}>
            <LastPage />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes, payments } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <FormSection name="paymentHistory">
              <Grid
                container
                style={{
                  margin: 0,
                  width: '100%',
                }}
                direction="row"
              >
                <Grid item xs={12} style={{ marginTop: '5%' }}>
                  <Typography
                    variant="h5"
                    className={classes.spacing}
                    color="primary"
                  >
                    Your Payment History
                  </Typography>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} className={classes.textBottomSpacing}>
                    <Typography variant="body1">
                      You can see all the payments you have made below, just
                      click on any tab to expand it for more information:{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {this.renderToolbar()}
                  </Grid>
                  <Grid item xs={12}>
                    {payments ? (
                      this.renderPayments()
                    ) : (
                      <Grid
                        item
                        container
                        xs={12}
                        justify="center"
                        alignContent="center"
                        style={{
                          height: '200px',
                        }}
                      >
                        <CircularProgress size={40} color="primary" />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </FormSection>
          </main>
        </Slide>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    form: state.form,
    payments: state.payments,
  };
}

PaymentHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  payments: PropTypes.object.isRequired,
  getPaymentHistory: PropTypes.func.isRequired,
};

const ConnectedPaymentHistory = connect(
  mapStateToProps,
  actions
)(PaymentHistory);

const PaymentHistoryRedux = reduxForm({
  //  validate,
  form: 'dashboardUpdateForm',
  destroyOnUnmount: false,
})(ConnectedPaymentHistory);

export default withStyles(styles)(PaymentHistoryRedux);
