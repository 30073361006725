import React, { Fragment } from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Typography,
  FormControl,
  Grid,
  withStyles,
} from '@material-ui/core';

import '../../styles/loginFormStyles.css';
import * as actions from '../../actions';
import getRecaptchaToken from '../../utils/getRecaptchaToken';

const styles = (theme) => ({
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
});

const authPinField = ({ input, id, label, meta: { touched, error } }) => (
  <FormControl fullWidth error={touched && error}>
    <InputMask mask="999999" {...input} onChange={input.onChange} maskChar="">
      {(inputProps) => (
        <TextField
          {...inputProps}
          autoFocus
          autoComplete="off"
          disableUnderline
          type="text"
          label={label}
          id={id}
          variant="outlined"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    </InputMask>
  </FormControl>
);

const handleLogin = async (props, values) => {
  const recaptchaToken = await getRecaptchaToken('login');
  const newValues = { ...values, token: props.token, recaptchaToken };
  props.login(newValues, props.history);
};

const handleResendPin = async (props) => {
  const recaptchaToken = await getRecaptchaToken('login');
  const res = await axios.post(
    `/api/auth/patient/two-factor-auth/${props.authMethod}`,
    { ...props, recaptchaToken }
  );

  if (res.data) {
    const authMethod = res.data === 'sms' ? 'SMS' : 'Email';

    toast.success(`${authMethod} with PIN was resent.`, {
      position: 'bottom-center',
      duration: 4000,
    });
  }
};

const AuthPinForm = (props) => {
  const { classes, handleSubmit } = props;
  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit((values) => handleLogin(props, values))}
    >
      <Fragment>
        <Grid item xs={12} style={{ margin: '15px 0px' }}>
          <Typography color="primary">
            We have sent a 6 digit PIN to <b>{props.recipient}</b>.
          </Typography>
        </Grid>
        <Field
          name="authPin"
          label="Enter PIN"
          type="text"
          component={authPinField}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={
            !props.authPin || (props.authPin && props.authPin.length < 6)
          }
        >
          Submit
        </Button>
        <Grid
          item
          xs={12}
          style={{ marginTop: '10px' }}
          container
          justify="center"
          spacing={1}
        >
          <Grid item xs={12} container justify="center">
            <Typography color="primary">{`Didn't get the PIN?`}</Typography>
          </Grid>
          <Grid item xs={12} container justify="center">
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={() =>
                handleResendPin({
                  authMethod: props.authMethod,
                  token: props.token,
                })
              }
            >
              Resend PIN
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    </form>
  );
};

const selector = formValueSelector('loginForm');

function mapStateToProps(state) {
  return {
    email: selector(state, 'username'),
    authPin: selector(state, 'authPin'),
    authMethod: selector(state, 'authMethod'),
  };
}
AuthPinForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  email: PropTypes.string,
  authPin: PropTypes.string,
  authMethod: PropTypes.string,
  login: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  recipient: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({ form: 'loginForm' })(withRouter(withStyles(styles)(AuthPinForm)))
);
