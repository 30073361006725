import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography, Button, withStyles } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import * as actions from '../../../actions';
import DailyDeliveriesCard from './DailyDeliveriesCard';
import MonthlyDeliveriesCard from './MonthlyDeliveriesCard';
import AvgDeliveryTimeCard from './AvgDeliveryTimeCard';
import ActiveDeliveriesCard from './ActiveDeliveriesCard';
import PendingDeliveriesCard from './PendingDeliveriesCard';

const styles = () => ({
  paper: {
    padding: '10px',
    height: '100%',
  },
  section: {
    marginLeft: '200px',
    marginRight: '20px',
  },
  sectionTab: {
    margin: 'auto 10px',
    borderRight: 'solid lightgray 1px',
  },
});

class DashboardSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 'Profile',
      deliveries: [],
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={4}>
          <Paper className={classes.paper}>
            <Typography color="primary" variant="h6">
              Daily Deliveries
            </Typography>
            <DailyDeliveriesCard />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper className={classes.paper}>
            <Typography color="primary" variant="h6">
              Prescriptions Received Monthly
            </Typography>
            <MonthlyDeliveriesCard />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper className={classes.paper}>
            <Typography color="primary" variant="h6">
              Avg. Delivery Time
            </Typography>
            <AvgDeliveryTimeCard />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={6}>
                <Typography color="primary" variant="h6">
                  Active Deliveries
                </Typography>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  startIcon={<Refresh />}
                  onClick={() => this.props.getActiveDeliveriesList()}
                >
                  Refresh
                </Button>
              </Grid>
            </Grid>
            <ActiveDeliveriesCard />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" color="error">
                  Pending Deliveries
                </Typography>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  startIcon={<Refresh />}
                  onClick={() => this.props.getPendingDeliveriesList()}
                >
                  Refresh
                </Button>
              </Grid>
            </Grid>
            <PendingDeliveriesCard />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

DashboardSection.propTypes = {
  classes: PropTypes.object.isRequired,
  getActiveDeliveriesList: PropTypes.func.isRequired,
  getPendingDeliveriesList: PropTypes.func.isRequired,
};

const ConnectedDashboardSection = connect(
  null,
  actions
)(withRouter(DashboardSection));

export default withStyles(styles)(ConnectedDashboardSection);
