import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';

import {
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  Slide,
  Snackbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import AuthSubscriptionAccordion from './AuthSubscriptionAccordion';
import MemberSubscriptionAccordion from './MemberSubscriptionAccordion';
import PaidVisitPurchase from '../dialogs/PaidVisitPurchase';
import StandardMembershipPurchase from '../dialogs/StandardMembershipPurchase';
import PremiumSubscriptionPurchase from '../dialogs/PremiumSubscriptionPurchase';
import CancelRenewal from '../dialogs/CancelRenewal';
import ResumeRenewal from '../dialogs/ResumeRenewal';
import UpgradePlan from '../dialogs/UpgradePlan';
import * as actions from '../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  loadingIcon: {
    marginTop: theme.spacing.unit * 3,
  },
  memberContainer: {
    marginTop: theme.spacing.unit,
  },
  purchaseButton: {
    marginTop: theme.spacing.unit * 2,
  },
  header: {
    margin: 0,
    width: '100%',
  },
  title: {
    marginTop: '5%',
    marginBottom: 15,
  },
});

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

class ManageSubscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      success: false,
      message: undefined,
      showPaidVisitDialog: false,
      showStandardDialog: false,
      showPremiumDialog: false,
      showCancelDialog: false,
      showRenewDialog: false,
      showUpgradeDialog: false,
      standardPlan: undefined,
      premiumPlan: undefined,
      fiveCreditPlan: undefined,
      selectedUser: undefined,
      authAccordion: undefined,
      familyMembers: [],
      creditBalance: props.auth.credits,
      accountType: props.auth.familyMembers.length > 0 ? 'family' : 'personal',
    };
  }

  componentDidMount = async () => {
    await this.refreshData();
  };

  refreshData = async () => {
    // start requests so we can resolve when needed
    const checkForSub = (plans, id) =>
      plans.find((plan) => plan.subMetadata.userId === id);

    let standardPlan = this.getStandardPlanData();
    let premiumPlan = this.getPremiumPlanData();
    let fiveCreditPlan = this.getFiveCreditData();

    const userPlans = await this.props.fetchUserPlans();
    const hasStandard = userPlans.standard && userPlans.standard.length > 0;
    const authStandardSub =
      hasStandard && checkForSub(userPlans.standard, this.props.auth._id);
    const hasPremium = userPlans.premium && userPlans.premium.length > 0;
    const members = [];

    const standardExpirationDate = authStandardSub
      ? new Date(authStandardSub.planEndDate * 1000)
      : null;
    const premiumExpirationDate =
      hasPremium && userPlans.premium[0]?.planEndDate
        ? new Date(userPlans.premium[0].planEndDate * 1000)
        : null;

    const { auth } = this.props;
    this.setState({
      authAccordion: {
        id: auth._id,
        name: `${auth.name.first} ${auth.name.last}`,
        standardMembershipStatus: !!authStandardSub,
        standardMembershipExpire: standardExpirationDate,
        premiumSubscriptionStatus: hasPremium,
        premiumSubscriptionPlan: hasPremium
          ? userPlans.premium[0].planName
          : null,
        premiumSubscriptionCoverage: hasPremium
          ? userPlans.premium[0].planMetadata.coverage
          : null,
        premiumSubscriptionBalance: auth.subscriptionVisitBalance,
        premiumSubscriptionExpire: premiumExpirationDate,
        premiumSubscriptionRenews:
          hasPremium && !userPlans.premium[0].cancelAtPeriodEnd,
      },
    });

    auth.familyMembers.forEach((member) => {
      const memberStandardSub =
        hasStandard && checkForSub(userPlans.standard, member._id);
      members.push({
        id: member._id,
        name: `${member.name.first} ${member.name.last}`,
        standardMembershipStatus: Boolean(memberStandardSub),
        standardMembershipExpire: memberStandardSub
          ? new Date(memberStandardSub.planEndDate * 1000)
          : null,
        ohipNumber: member.ohipNumber,
        finishedProfile: member.finishedProfile,
      });
    });

    // resolve promises
    [standardPlan, premiumPlan, fiveCreditPlan] = await Promise.allSettled([
      standardPlan,
      premiumPlan,
      fiveCreditPlan,
    ]);

    this.setState({
      standardPlan: standardPlan.value,
      premiumPlan:
        this.state.accountType === 'family'
          ? {
              ...premiumPlan.value.familyPlan.product,
              prices: premiumPlan.value.familyPlan.prices,
            }
          : {
              ...premiumPlan.value.personalPlan.product,
              prices: premiumPlan.value.personalPlan.prices,
            },
      fiveCreditPlan: {
        ...fiveCreditPlan.value.product,
        prices: fiveCreditPlan.value.prices,
      },
      familyMembers: members,
    });

    this.setState({ loading: false });
  };

  getStandardPlanData = async () => {
    try {
      const res = await axios.get('/api/billing/stripe/ohip-plans');
      return res.data;
    } catch (err) {
      this.handleError(err);
    }
    return null;
  };

  getPremiumPlanData = async () => {
    try {
      let personalPlan = axios.get('/api/billing/stripe/yearly-plan');
      let familyPlan = axios.get('/api/billing/stripe/yearly-family-plan');

      [personalPlan, familyPlan] = await Promise.allSettled([
        personalPlan,
        familyPlan,
      ]);

      return {
        personalPlan: personalPlan.value.data,
        familyPlan: familyPlan.value.data,
      };
    } catch (err) {
      this.handleError(err);
    }
    return null;
  };

  getFiveCreditData = async () => {
    try {
      const res = await axios.get('/api/billing/stripe/five-credit-package');
      return res.data;
    } catch (err) {
      this.handleError(err);
    }
    return null;
  };

  // eslint-disable-next-line class-methods-use-this
  handleError = (err) => {
    toast.error(err.message);
  };

  handleClose = () => this.setState({ error: false });

  handleStandardContinue = async (option) => {
    const { standardPlan, selectedUser } = this.state;

    await this.props.selectedUserChange({
      id: selectedUser.id,
      fullName: selectedUser.name,
    });

    this.props.history.push({
      pathname: '/checkout',
      state: {
        product: {
          id: standardPlan.id,
          name: standardPlan.name,
          description: standardPlan.description,
          duration_in_hours: option.metadata.duration_in_hours,
          currency: option.currency,
          unit_amount: option.unit_amount,
          interval: option.recurring.interval,
          price_id: option.id,
          plan_type: 'OHIP',
          product_type: 'Subscription',
        },
        origin: '/dashboard/manageSubscriptions',
        redirect: '/dashboard/manageSubscriptions',
      },
    });
  };

  handlePremiumContinue = () => {
    if (this.state.accountType === 'family') {
      this.props.history.push(
        '/PaymentPlanMembership/YearlyFamilyPlan/dashboard'
      );
    } else {
      this.props.history.push('/PaymentPlanMembership/YearlyPlan/dashboard');
    }
  };

  handlePaidVisitContinue = () => {
    this.props.history.push(
      '/PaymentPlanMembership/FiveCreditPackage/dashboard'
    );
  };

  handleCancelContinue = async () => {
    try {
      const url =
        this.state.accountType === 'family'
          ? '/api/billing/stripe/RemoveFamilyPlan'
          : '/api/billing/stripe/RemovePlan';

      const response = await axios.post(url, {
        customerId: this.props.auth.customerId,
      });

      if (response.data) {
        this.props.updateUser(response.data);
        this.setState(() => ({ showCancelDialog: false }));
        window.location.reload();
      }
    } catch (err) {
      this.handleError(err);
    }
  };

  handleRenewContinue = async () => {
    try {
      const url =
        this.state.accountType === 'family'
          ? '/api/billing/stripe/ReAddFamilyPlan'
          : '/api/billing/stripe/ReAddPlan';

      const response = await axios.post(url, {
        customerId: this.props.auth.customerId,
      });

      if (response.data) {
        this.props.updateUser(response.data);
        this.setState(() => ({ showRenewDialog: false }));
        window.location.reload();
      }
    } catch (err) {
      this.handleError(err);
    }
  };

  handleUpgradeContinue = async () => {
    try {
      const response = await axios.post(
        '/api/billing/stripe/UpdatePlanToYearlyFamilyPlan',
        {
          customerId: this.props.auth.customerId,
        }
      );
      if (response.data) {
        this.props.fetchUser().then(async () => this.refreshData());
        this.setState({
          showUpgradeDialog: false,
        });
      }
    } catch (err) {
      this.handleError(err);
    }
  };

  openStandardDialog = (member) =>
    this.setState({
      showStandardDialog: true,
      selectedUser: { id: member.id, name: member.name },
    });

  openPremiumDialog = () => this.setState({ showPremiumDialog: true });

  openPaidVisitDialog = () => this.setState({ showPaidVisitDialog: true });

  openCancelDialog = () => this.setState({ showCancelDialog: true });

  openRenewDialog = () => this.setState({ showRenewDialog: true });

  openUpgradeDialog = () => this.setState({ showUpgradeDialog: true });

  openOhipScreen = (id) => {
    this.props.selectedUserChange({ id }).then(() => {
      this.props.history.push('/dashboard/AboutMeMedical/editOhipCard');
      this.props.changePage('AboutMeMedical');
    });
  };

  render() {
    const { classes } = this.props;
    const { standardPlan, premiumPlan, fiveCreditPlan } = this.state;

    return (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Grid container className={classes.header} direction="row">
              <Grid item xs={12} className={classes.title}>
                <Typography variant="h5" color="primary">
                  Manage Subscriptions
                </Typography>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>
                    Subscription management for all account holders and family
                    members.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {this.state.loading ? (
              <Grid
                container
                justify="center"
                className={classes.memberContainer}
              >
                <Grid item>
                  <CircularProgress size={25} color="primary" />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} className={classes.memberContainer}>
                <Grid item xs={12}>
                  <Typography variant="h6" color="primary">
                    Subscriptions
                  </Typography>
                </Grid>
                <AuthSubscriptionAccordion
                  key={0}
                  member={this.state.authAccordion}
                  standardDescription={standardPlan.description}
                  premiumDescription={premiumPlan.description}
                  openStandardDialog={() =>
                    this.openStandardDialog(this.state.authAccordion)
                  }
                  openPremiumDialog={this.openPremiumDialog}
                  openCancelDialog={this.openCancelDialog}
                  openRenewDialog={this.openRenewDialog}
                  openUpgradeDialog={this.openUpgradeDialog}
                  openOhipScreen={() =>
                    this.openOhipScreen(this.props.auth._id)
                  }
                />
                {this.state.familyMembers.map((member, i) => {
                  if (member.finishedProfile) {
                    return (
                      <MemberSubscriptionAccordion
                        key={i + 1}
                        member={member}
                        standardDescription={standardPlan.description}
                        openStandardDialog={() => {
                          this.openStandardDialog(member);
                        }}
                        openOhipScreen={(id) => this.openOhipScreen(id)}
                      />
                    );
                  }
                  return null;
                })}

                <Grid item xs={12}>
                  <Typography variant="h6" color="primary">
                    Private Paid Visits Packages
                  </Typography>
                  <Typography variant="body2">
                    {this.state.fiveCreditPlan.description}
                  </Typography>
                  <br />
                  <Typography>
                    <b>Balance:</b> {this.state.creditBalance || 0} paid visits
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.purchaseButton}
                    onClick={this.openPaidVisitDialog}
                  >
                    Purchase
                  </Button>
                </Grid>
              </Grid>
            )}
          </main>
        </Slide>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={this.handleClose}
          open={this.state.error || this.state.success}
          autoHideDuration={5000}
        >
          <Alert severity={this.state.error ? 'error' : 'success'}>
            {this.state.message}
          </Alert>
        </Snackbar>

        <PaidVisitPurchase
          open={this.state.showPaidVisitDialog}
          onClose={() => this.setState({ showPaidVisitDialog: false })}
          onContinue={this.handlePaidVisitContinue}
          product={fiveCreditPlan}
        />

        <StandardMembershipPurchase
          open={this.state.showStandardDialog}
          onClose={() => this.setState({ showStandardDialog: false })}
          onContinue={this.handleStandardContinue}
          product={standardPlan}
        />

        <PremiumSubscriptionPurchase
          open={this.state.showPremiumDialog}
          onClose={() => this.setState({ showPremiumDialog: false })}
          onContinue={this.handlePremiumContinue}
          product={premiumPlan}
        />

        <CancelRenewal
          open={this.state.showCancelDialog}
          onClose={() => this.setState({ showCancelDialog: false })}
          onContinue={this.handleCancelContinue}
        />

        <ResumeRenewal
          open={this.state.showRenewDialog}
          onClose={() => this.setState({ showRenewDialog: false })}
          onContinue={this.handleRenewContinue}
        />

        <UpgradePlan
          open={this.state.showUpgradeDialog}
          onClose={(options) => {
            this.setState({ showUpgradeDialog: false });
            if (options && options.redirect) {
              if (options.path) {
                this.props.history.push(options.path);
              }
              this.props.changePage(options.page);
              setTimeout(
                () =>
                  document.querySelector('#toolbar-section').scrollIntoView({
                    behavior: 'smooth',
                  }),
                500
              );
            }
          }}
          onContinue={this.handleUpgradeContinue}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

ManageSubscriptions.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  selectedUserChange: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  fetchUserPlans: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
};

const ConnectedManageSubscriptions = connect(
  mapStateToProps,
  actions
)(withRouter(ManageSubscriptions));

export default withStyles(styles)(ConnectedManageSubscriptions);
